import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = {
  key: 1,
  class: "btnWrap"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Warning = _resolveComponent("Warning")!

  return (_openBlock(), _createElementBlock("div", {
    class: "modal",
    onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.alert)
      }, [
        _createVNode(_component_Warning),
        _createElementVNode("h2", null, _toDisplayString(_ctx.message), 1),
        (_ctx.tempDists.length)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createElementVNode("p", null, "或搜尋以下地區的" + _toDisplayString(_ctx.sportName) + "場地", 1),
              _createElementVNode("p", {
                class: _normalizeClass(_ctx.$style.wrap)
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dists, (dist) => {
                  return (_openBlock(), _createElementBlock("span", { key: dist }, _toDisplayString(dist), 1))
                }), 128))
              ], 2)
            ], 64))
          : _createCommentVNode("", true),
        (_ctx.alertType !== _ctx.AlertType.ServerError)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_ctx.tempDists.length)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.tempToStore(_ctx.cntSport, _ctx.tempDists)))
                  }, " 搜尋場地 "))
                : _createCommentVNode("", true),
              _createElementVNode("div", {
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.resetFilter && _ctx.resetFilter(...args)))
              }, "重新查詢")
            ]))
          : _createCommentVNode("", true)
      ], 2)
    ])
  ]))
}