
import { useStore, mutations } from '@/store/index'
import { defineComponent, ref, computed, PropType } from 'vue'
import { ISchedule, ModalType, _global } from '@/typings'
import { detectTouchMove } from '@/helper/utils'
import { changeUrl } from '@/helper/home'
import Nav from '@/components/InfoModal/Nav.vue'
import LeftSide from '@/components/InfoModal/LeftSide.vue'
import VenueInfo from '@/components/InfoModal/VenueInfo.vue'
import BookingInfo from '@/components/InfoModal/BookingInfo.vue'
import BookingCourt from '@/components/InfoModal/BookingCourt.vue'
import API from '@/api/index'

export default defineComponent({
  name: 'InfoModal',
  components: {
    Nav,
    LeftSide,
    BookingInfo,
    BookingCourt,
    VenueInfo,
  },
  props: {
    resetFilter: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },

  setup() {
    const store = useStore()
    const isInfoOpen = ref(false)
    const hourlyCourts = computed(() => store.getters.hourlyCourts)
    const isBooking = computed(() => store.state.control.isBooking)

    const setBooking = (boo: boolean) => {
      store.commit(mutations.control.SET_BOOKING, boo)
      if (!boo) changeUrl()
    }

    const selectCourt = (court: ISchedule.ICourt) => {
      setBooking(true)
      store.commit(mutations.modal.SET_MODAL_BOOKINGINFO, court)
      changeUrl('addTimeslot', court.timeslotId)
    }

    const venueInfo = computed(() => {
      const id = store.state.modalData.data.venueId
      return store.state.mainData.venuesInfo?.[id]
    })

    const openLink = (link: 'login' | 'booking') => {
      const { timeslotId, remark } = store.state.modalData.bookingInfo
      const modalInfoSum = store.getters.modalInfoSum
      const { providerId } = venueInfo.value

      _global.sentGA('click', {
        target: link,
        targetInfo: `${modalInfoSum.venueName}-${modalInfoSum.sportName}`,
      })

      if (link === 'login') {
        let loginUrl = ''
        if (providerId === ISchedule.ProviderEnum.Official) {
          const strIndex = remark.bookingUrl?.search('net_booking')
          loginUrl = remark.bookingUrl
            ?.slice(0, strIndex)
            .concat('login_page&files=login')
        }

        if (providerId === ISchedule.ProviderEnum.Fit17) {
          loginUrl =
            'https://17fit.com/oauth/login?redirect_uri=https://17fit.com/socialite/login/callback&show_fb=1'
        }

        const result = window.confirm(
          '將外連至官方預訂平台，登入後請返回 Sportable 點擊第 2 步完成預定'
        )
        if (result) {
          window.open(loginUrl, '_blank', 'noreferrer, noopenner')
        }
      }
      if (link === 'booking') {
        if (providerId === ISchedule.ProviderEnum.Official) {
          window.open(remark.bookingUrl, '_blank', 'noreferrer, noopenner')
        }
        if (providerId === ISchedule.ProviderEnum.Fit17 && remark.payload) {
          API.postFit17Data(remark.bookingUrl, remark.payload)
        }
        API.postClickedCourt(timeslotId).then(() => {
          store.dispatch('GET_DETAIL')
        })
      }
    }

    const closeModal = () => {
      store.commit(mutations.control.SET_MODAL, ModalType.None)
      changeUrl()
    }

    const touchFunc = {
      rightFunc: () => {
        const timeslotId = store.state.modalData.bookingInfo.timeslotId
        if (timeslotId) {
          setBooking(true)
          changeUrl('addTimeslot', timeslotId)
        }
      },

      leftFunc: () => {
        if (isInfoOpen.value) {
          isInfoOpen.value = false
          return
        }
        isBooking.value ? setBooking(false) : closeModal()
      },
    }
    const touchEvent = detectTouchMove(touchFunc)

    return {
      touchEvent,
      isBooking,
      isInfoOpen,
      hourlyCourts,
      venueInfo,
      setBooking,
      selectCourt,
      openLink,
      closeModal,
    }
  },
})
