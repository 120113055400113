import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OptionsSport = _resolveComponent("OptionsSport")!
  const _component_OptionsDist = _resolveComponent("OptionsDist")!
  const _component_Header = _resolveComponent("Header")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_ScheduleBasic = _resolveComponent("ScheduleBasic")!
  const _component_ScheduleDetail = _resolveComponent("ScheduleDetail")!
  const _component_Footer = _resolveComponent("Footer")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header, {
      needUpdate: _ctx.needUpdate,
      getTimeTableData: _ctx.getTimeTableData
    }, {
      sport: _withCtx(({ closeFunc }) => [
        _createVNode(_component_OptionsSport, {
          submit: _ctx.submit,
          closeFunc: closeFunc,
          type: "filter"
        }, null, 8, ["submit", "closeFunc"])
      ]),
      dist: _withCtx(({ closeFunc }) => [
        _createVNode(_component_OptionsDist, {
          submit: _ctx.submit,
          closeFunc: closeFunc,
          type: "filter"
        }, null, 8, ["submit", "closeFunc"])
      ]),
      _: 1
    }, 8, ["needUpdate", "getTimeTableData"]),
    _createElementVNode("main", null, [
      _createElementVNode("section", {
        class: _normalizeClass(_ctx.$style.top)
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.$style.wrap)
        }, [
          (_ctx.isMobile)
            ? (_openBlock(), _createBlock(_component_Calendar, { key: 0 }))
            : (_openBlock(), _createBlock(_component_ScheduleBasic, { key: 1 })),
          _createElementVNode("h3", {
            class: _normalizeClass(_ctx.$style.dateTitle)
          }, _toDisplayString(_ctx.cntDateStr), 3)
        ], 2)
      ], 2),
      _createElementVNode("section", {
        class: _normalizeClass([_ctx.$style.bottom, _ctx.$style.wrap])
      }, [
        _createVNode(_component_ScheduleDetail),
        _createVNode(_component_Footer)
      ], 2)
    ]),
    _createVNode(_component_Modal, { tempToStore: _ctx.tempToStore }, {
      sport: _withCtx(() => [
        _createVNode(_component_OptionsSport, {
          submit: _ctx.submit,
          type: "modal"
        }, null, 8, ["submit"])
      ]),
      dist: _withCtx(() => [
        _createVNode(_component_OptionsDist, {
          submit: _ctx.submit,
          type: "modal"
        }, null, 8, ["submit"])
      ]),
      _: 1
    }, 8, ["tempToStore"])
  ], 64))
}