
import { defineComponent, PropType, computed } from 'vue'
import { AlertType } from '@/typings'
import { useStore } from '@/store/index'
import Warning from '@/assets/icons/warning.svg'

export default defineComponent({
  name: 'Alert',
  components: { Warning },
  props: {
    resetFilter: {
      type: Function as PropType<() => void>,
      required: true,
    },
    tempToStore: {
      type: Function as PropType<(spid?: number, dists?: number[]) => void>,
      required: true,
    },
  },
  setup() {
    const store = useStore()
    const alertType = computed(() => store.state.control.alertType)
    const tempDists = computed(() => store.state.currentSelect.tempDists)

    const message = computed(() => {
      switch (alertType.value) {
        case AlertType.KeyNotFound:
          return '找不到場地'

        case AlertType.TimeslotNotFound:
          return '搜尋的場地不存在'

        case AlertType.ServerError:
          return '系統維護中，敬請期待'

        case AlertType.WrongCriteria:
          return '搜尋條件錯誤'

        default:
          return ''
      }
    })

    const dists = computed(() => {
      return tempDists.value.map((id) => store.getters.districtName(id))
    })

    return {
      AlertType,
      sportName: computed(() => store.getters.sportName),
      tempDists: computed(() => store.state.currentSelect.tempDists),
      cntSport: computed(() => store.state.currentSelect.currentSport),
      alertType,
      message,
      dists,
    }
  },
})
