
import { defineComponent, onMounted } from 'vue'
import { useStore, mutations } from '@/store/index'

export default defineComponent({
  name: 'App',
  setup() {
    const store = useStore()

    const onResize = () => {
      const mobileMatched = window.matchMedia('(max-width: 820px)').matches

      const isMobile = store.state.control.isMobile
      if (isMobile !== mobileMatched) {
        store.commit(mutations.control.SET_DEVICE, mobileMatched)
      }
    }

    window.addEventListener('resize', onResize, { passive: true })

    onMounted(() => {
      onResize()
    })
  },
})
