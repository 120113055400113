
import { useStore } from '@/store/index'
import { defineComponent, computed } from 'vue'
import { getDateStr } from '@/helper/schedule'

export default defineComponent({
  name: 'LeftSide',
  setup() {
    const store = useStore()
    const content = computed(() => {
      const modalData = store.state.modalData.data
      const subData = store.state.modalData.subData
      const sportName = store.getters.sportName
      const courtPrice = store.getters.courtPrice
      const distList = store.state.mainData.dists[modalData.districtId]

      return [
        { title: '運動', text: sportName },
        {
          title: '區域',
          text: `${distList.cityName} ${distList.districtName}`,
        },
        { title: '日期', text: getDateStr(modalData.date) },
        {
          title: '時間',
          text: subData.end
            ? `${modalData.start}:00  - ${subData.end}:00`
            : '--',
        },
        {
          title: '場租',
          text: courtPrice ? courtPrice : '--',
        },
      ]
    })

    return {
      content,
    }
  },
})
