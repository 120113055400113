import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["title"]
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(_ctx.$style[_ctx.btyType]),
    title: _ctx.title
  }, [
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.btnName))),
    (_ctx.message)
      ? (_openBlock(), _createElementBlock("small", _hoisted_2, _toDisplayString(_ctx.message), 1))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}