import { ICourt } from '@/typings/Schedule'

export enum FilterStep {
  Sport, // 👉 val: 0, `open sport filter`
  Dists, // 👉 val: 1, `open dists filter`
}

export enum ModalType {
  None,
  Loading = 'Loading',
  Directions = 'Directions',
  Terms = 'Terms',
  FilterModal = 'FilterModal',
  InfoModal = 'InfoModal',
  Alert = 'Alert',
  Crowd = 'Crowd',
}

export enum AlertType {
  None,
  WrongCriteria = 1,
  KeyNotFound = 400,
  TimeslotNotFound = 404,
  ServerError = 500,
}

export interface ISpt2Dist {
  sportId: number
  sportName: string
  sportNameEn: string
  districts?: IDistricts[]
}

export interface IDistricts extends IDist {
  available: boolean
}

export interface ICityList {
  [cityId: string]: {
    cityName: string
    cityId: string
    dists: number[]
  }
}

export interface IDist {
  cityId: string
  cityName: string
  districtId: number
  districtName: string
}

export interface IDist2 extends IDist {
  sports: number[]
  venues: number[]
}

export interface IVenue {
  venueId: number
  companyId: number
  distId: number
  venueName: string
  venueShortName: string
  companyName: string
  companyType: string
  cityId: string
  cityName: string
  url: string
  status: 'active' | 'develop'
}

export interface IDate {
  dateStr: string
  weekday: string
}

type TimeslotId = number

export interface Schedule2 {
  date: number
  hours: {
    hour: number
    data: {
      districtId: number
      venueId: number
      timeslotId: TimeslotId[]
    }[]
  }[]
}

interface Venue {
  districtId: number
  venueId: number
}

export interface HourData extends Venue {
  courts: TimeslotId[]
}

export interface VenueCourt extends Venue {
  hours: {
    [hour: number]: TimeslotId[]
  }
}

export interface IScheduleData {
  timestamp: number
  hours: {
    [hour: number]: {
      [dist: number]: HourData
    }
  }
  sum: { [districtId: number]: number }
  venues: {
    [dist: number]: VenueCourt
  }
}

export interface CourtData extends ICourt {
  sportId: number
  districtId: number
  venueId: number
  start: number
  end: number
  sportName: string
  venueName: string
  venueShortName: string
}

export interface Hint {
  timeslotId: TimeslotId
  clicked: boolean
}

export type crowd = {
  count: number
  capacity: number
}

export interface CrowdData {
  venueId: number
  gym: crowd
  swim: crowd
  ice?: crowd
}

export const _global = window as any

export * as ISchedule from './Schedule'
