
import { defineComponent, onMounted, ref, computed } from 'vue'
import { useStore, mutations } from '@/store/index'
import { ModalType, CrowdData, crowd } from '@/typings'
import { arrToObj } from '@/helper/utils'
import Button from '@/components/generic/Button.vue'
import ScrollWrap from '@/components/generic/ScrollWrap.vue'
import API from '@/api/index'

export default defineComponent({
  name: 'Crowd',
  components: {
    Button,
    ScrollWrap,
  },

  setup() {
    const store = useStore()
    const distList = computed(() => store.state.mainData.distsList)
    const data = ref<{ [id: number]: CrowdData }>({})
    const cntCity = ref(Object.keys(distList.value)[0])
    const venueList = computed(() => store.state.mainData.venueList)

    const fetchData = async () => {
      await API.fetchCrowdCount().then((res) => {
        data.value = arrToObj(res, 'venueId')
      })
    }

    const levelColor = (_c: crowd) => {
      const num = Math.round((_c.count / _c.capacity) * 100)
      if (num <= 33) {
        return 'high'
      }
      if (num <= 66) {
        return 'mid'
      }
      if (num > 66) {
        return 'low'
      }
    }

    onMounted(() => {
      fetchData()
    })

    enum Placetype {
      gym = 'gym',
      swim = 'swim',
    }

    return {
      Placetype,
      ModalType,
      distList,
      venueList,
      cntCity,
      data,
      levelColor,
      setModal: (type: ModalType) =>
        store.commit(mutations.control.SET_MODAL, type),
    }
  },
})
