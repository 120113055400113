
import { computed, defineComponent } from 'vue'
import { useStore, mutations } from '@/store/index'
import TimeslotBasic from '@/components/schedule/TimeslotBasic.vue'
import Arrow from '@/assets/icons/arrow-down.svg'
import { findFirstDate } from '@/helper/home'

export default defineComponent({
  name: 'ScheduleBasic',
  components: {
    TimeslotBasic,
    Arrow,
  },

  setup() {
    const store = useStore()
    const dateHasData = computed(() => store.getters.dateHasData)

    return {
      cntWeekDates: computed(() => store.getters.cntWeekDates),
      currentWeek: computed(() => store.state.currentSelect.currentWeek),
      currentDate: computed(() => store.state.currentSelect.currentDate),
      cntDists: computed(() => store.state.currentSelect.currentDistricts),
      choseDate: (date: string) =>
        dateHasData.value(date) &&
        store.commit(mutations.cntSelect.CHOSE_DATE, date),
      changeWeek: (num: number) => {
        store.commit(mutations.cntSelect.CHANGE_WEEK, num)
        findFirstDate()
      },
    }
  },
})
