
import { defineComponent, PropType, computed, ref } from 'vue'
import { useStore, mutations } from '@/store/index'
import { ModalType } from '@/typings'
import Button from '@/components/generic/Button.vue'
import Logo from '@/assets/logos/sportable-logo.svg'

export default defineComponent({
  name: 'Directions',
  components: {
    Button,
    Logo,
  },
  props: {
    resetFilter: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },
  setup(props) {
    const store = useStore()
    const step = ref(0)
    const wrap = ref<HTMLElement>()
    const width = computed(() => (wrap.value ? wrap.value.offsetWidth : 0))
    const isTouched = ref(false)

    const requireImage = (link: string) => {
      return require(`@/assets/images/${link}`)
    }
    const contentDesktop = [
      {
        image: requireImage('01.png'),
        title: '資訊整合',
        text: '整合多方平台公開資訊，資訊一覽無遺',
      },
      // {
      //   image: requireImage('02.png'),
      //   title: '瀏覽模式—單日',
      //   text: '呈現所選地區單日可預約時段',
      // },
      // {
      //   image: requireImage('03.png'),
      //   title: '瀏覽模式—時刻',
      //   text: '呈現所選地區一週可預約場館總覽',
      // },
      {
        image: requireImage('04.png'),
        title: '一鍵預約',
        text: '一鍵完成場地預約，方便快速',
      },
    ]

    const contentMobile = [
      {
        image: requireImage('01-mobile.png'),
        title: contentDesktop[0].title,
        text: contentDesktop[0].text,
      },
      contentDesktop[1],
    ]

    const content = computed(() => {
      const isMobile = store.state.control.isMobile
      return isMobile ? contentMobile : contentDesktop
    })

    const closeModal = () => {
      const isFirstVisit = store.state.control.isFirstVisit
      if (isFirstVisit) {
        props.resetFilter()
        return
      }
      store.commit(mutations.control.SET_MODAL, ModalType.None)
    }

    const switchStep = () => {
      isTouched.value = false

      if (step.value === content.value.length) {
        closeModal()
        return
      } else {
        step.value += 1
      }

      if (!wrap.value) return
      wrap.value.scrollTo({
        left: width.value * step.value,
        behavior: 'smooth',
      })
    }
    const scrollFunc = () => {
      if (!wrap.value) return
      const scrollLeft = wrap.value.scrollLeft
      const num = Math.round(scrollLeft / width.value)
      if (num !== step.value) {
        step.value = num
      }
    }

    return {
      isTouched,
      content,
      step,
      wrap,
      closeModal,
      switchStep,
      scrollFunc,
    }
  },
})
