
import { defineComponent, PropType } from 'vue'
import Arrow from '@/assets/icons/arrow-down.svg'
import Back from '@/assets/icons/arrow-left.svg'
import Check from '@/assets/icons/check.svg'
import Close from '@/assets/icons/close-24.svg'
import Info from '@/assets/icons/info.svg'
import Link from '@/assets/icons/link.svg'
import Reload from '@/assets/icons/reload.svg'
import Search from '@/assets/icons/search.svg'
import Crowd from '@/assets/icons/crowd.svg'

export default defineComponent({
  name: 'Button',
  components: {
    Arrow,
    Back,
    Check,
    Close,
    Info,
    Link,
    Reload,
    Search,
    Crowd,
  },
  props: {
    btnName: {
      type: String,
      required: true,
    },
    btyType: {
      type: String as PropType<'close' | 'tooltip' | 'fillBtn' | 'basic'>,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    message: {
      type: String,
    },
  },
})
