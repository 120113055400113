import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logo = _resolveComponent("Logo")!
  const _component_Filter = _resolveComponent("Filter")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Bookmark = _resolveComponent("Bookmark")!

  return (_openBlock(), _createElementBlock("header", null, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.isMobile || _ctx.isShrink ? _ctx.$style.shrink : _ctx.$style.default)
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.logo)
      }, [
        _createVNode(_component_Logo)
      ], 2),
      (_ctx.isMobile || _ctx.isShrink)
        ? (_openBlock(), _createBlock(_component_Filter, {
            key: 0,
            needUpdate: _ctx.needUpdate,
            getTimeTableData: _ctx.getTimeTableData
          }, {
            sport: _withCtx(({ closeFunc }) => [
              _renderSlot(_ctx.$slots, "sport", { closeFunc: closeFunc })
            ]),
            dist: _withCtx(({ closeFunc }) => [
              _renderSlot(_ctx.$slots, "dist", { closeFunc: closeFunc })
            ]),
            _: 3
          }, 8, ["needUpdate", "getTimeTableData"]))
        : _createCommentVNode("", true),
      _createVNode(_component_Button, {
        class: _normalizeClass(_ctx.$style.crowdBtn),
        btnName: "Crowd",
        btyType: "tooltip",
        title: "場館即時人數",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setModal(_ctx.ModalType.Crowd)))
      }, null, 8, ["class"]),
      _createVNode(_component_Button, {
        class: _normalizeClass(_ctx.$style.infoBtn),
        btnName: "Info",
        btyType: "tooltip",
        title: "操作說明",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setModal(_ctx.ModalType.Directions)))
      }, null, 8, ["class"]),
      (!_ctx.isMobile && !_ctx.isShrink)
        ? (_openBlock(), _createBlock(_component_Filter, {
            key: 1,
            needUpdate: _ctx.needUpdate,
            getTimeTableData: _ctx.getTimeTableData
          }, {
            sport: _withCtx(({ closeFunc }) => [
              _renderSlot(_ctx.$slots, "sport", { closeFunc: closeFunc })
            ]),
            dist: _withCtx(({ closeFunc }) => [
              _renderSlot(_ctx.$slots, "dist", { closeFunc: closeFunc })
            ]),
            _: 3
          }, 8, ["needUpdate", "getTimeTableData"]))
        : _createCommentVNode("", true)
    ], 2),
    (_ctx.isMobile)
      ? (_openBlock(), _createBlock(_component_Bookmark, { key: 0 }))
      : _createCommentVNode("", true)
  ]))
}