
import { useStore, mutations } from '@/store/index'
import { _global, ISchedule } from '@/typings'
import { computed, defineComponent, PropType, ref } from 'vue'
import { getDateStr } from '@/helper/schedule'
import { timeoutFunc } from '@/helper/utils'
import Arrow from '@/assets/icons/arrow.svg'
import Warning from '@/assets/icons/warning.svg'
import Button from '@/components/generic/Button.vue'

export default defineComponent({
  name: 'BookingCourt',
  props: {
    closeBooking: {
      type: Function as PropType<() => void>,
      required: true,
    },
    openLink: {
      type: Function as PropType<(link: 'login' | 'booking') => void>,
      required: true,
    },
    resetFilter: {
      type: Function as PropType<() => void>,
      required: true,
    },
    closeModal: {
      type: Function as PropType<() => void>,
      required: true,
    },
    hasCourt: {
      type: Boolean,
      required: true,
    },
    venueInfo: {
      type: Object as PropType<ISchedule.VenueInfo>,
      // required: true,
    },
  },
  components: {
    Button,
    Arrow,
    Warning,
  },
  setup(props) {
    const store = useStore()
    const isCopied = ref(false)
    const isLinkOpen = ref(false)
    const bookingInfo = computed(() => store.state.modalData.bookingInfo)
    const errorObj = computed(() => {
      const { status, courtName } = store.state.modalData.error
      let title = ''
      let message = ''
      let hasApp = false

      if (status === 'idle') {
        let provider =
          (props.venueInfo?.providerId === ISchedule.ProviderEnum.TaipeiApp &&
            '台北市智慧運動中心') ||
          (props.venueInfo?.providerId === ISchedule.ProviderEnum.CjApp &&
            '長佳智慧運動中心')

        hasApp = true
        title = '請使用場館 App、電話或臨櫃預約'
        message = `下載${provider} App`
      }
      if (status === 'full') {
        title = `${courtName}已被預訂`
        message = props.hasCourt ? '請選擇其他場地' : ' 該時段已無其他場地'
      }
      if (status === 'expired') {
        title = '場地已逾期'
        message = '請選擇其他時段'
      }

      return {
        status,
        title,
        message,
        hasApp,
      }
    })

    const copyLink = () => {
      const path = document.URL
      const hasClipboard = navigator.clipboard

      const modalData = store.state.modalData.data
      const modalInfoSum = store.getters.modalInfoSum
      const bookingstr = `預訂 ${getDateStr(modalData.date)} ${
        modalData.start
      }:00-${store.state.modalData.subData.end}:00 
${modalInfoSum.cityName}${modalInfoSum.venueName}-${modalInfoSum.sportName}場 ${
        bookingInfo.value.courtName
      }`

      if (hasClipboard) {
        navigator.clipboard.writeText(bookingstr + '\n' + path)
      } else {
        const el = document.createElement('textarea')
        el.value = bookingstr + '\n' + path
        document.body.appendChild(el)
        el.select()
        document.execCommand('copy')
        document.body.removeChild(el)
      }
      _global.sentGA('click', {
        target: 'share_link',
        targetInfo: `${modalInfoSum.venueName}-${modalInfoSum.sportName}`,
      })

      timeoutFunc(isCopied, 800)
    }

    return {
      isLinkOpen,
      isCopied,
      copyLink,
      bookingInfo,
      errorObj,
      resetError: () =>
        store.commit(mutations.modal.SET_MODAL_ERROR, {
          status: 'idle',
          courtName: '',
        }),
    }
  },
})
