
import { defineComponent, computed } from 'vue'
import { levelColor } from '@/helper/schedule'
import { useStore } from '@/store/index'

export default defineComponent({
  name: 'TimeslotBasic',
  props: {
    dateStr: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const store = useStore()

    return {
      levelColor,
      sumData: computed(
        () => store.state.mainData.schedule[props.dateStr]?.sum
      ),
      districtName: computed(() => store.getters.districtName),
      currentDistricts: computed<number[]>(
        () => store.state.currentSelect.currentDistricts
      ),
    }
  },
})
