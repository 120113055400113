import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Search = _resolveComponent("Search")!
  const _component_Close = _resolveComponent("Close")!
  const _component_Arrow = _resolveComponent("Arrow")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.filters)
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.selection),
      onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (
        !_ctx.isMobile
          ? _ctx.openSelector(_ctx.FilterStep.Sport)
          : _ctx.setModal(_ctx.ModalType.FilterModal)
      ), ["stop"]))
    }, [
      _createElementVNode("span", null, [
        _createTextVNode(_toDisplayString(_ctx.sportName ? _ctx.sportName : '請選擇運動') + " ", 1),
        _createVNode(_component_Search)
      ]),
      _createVNode(_Transition, { name: "option" }, {
        default: _withCtx(() => [
          (_ctx.isOptionOpen && _ctx.filterStep === _ctx.FilterStep.Sport)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(_ctx.$style.optionWrap)
              }, [
                _renderSlot(_ctx.$slots, "sport", {
                  closeFunc: () => (_ctx.isOptionOpen = false)
                })
              ], 2))
            : _createCommentVNode("", true)
        ]),
        _: 3
      })
    ], 2),
    (!_ctx.isMobile)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(_ctx.$style.selection),
          onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.openSelector(_ctx.FilterStep.Dists)), ["stop"]))
        }, [
          (_ctx.distsId?.length === 0)
            ? (_openBlock(), _createElementBlock("span", _hoisted_1, "請選擇地區"))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.distsId, (id) => {
            return (_openBlock(), _createElementBlock("span", {
              key: id,
              class: _normalizeClass(_ctx.$style.close)
            }, [
              _createTextVNode(_toDisplayString(_ctx.districtName(id)) + " ", 1),
              _createVNode(_component_Close, {
                onClick: _withModifiers(($event: any) => (_ctx.removeDist(id)), ["stop"])
              }, null, 8, ["onClick"])
            ], 2))
          }), 128)),
          _createVNode(_component_Arrow, {
            class: _normalizeClass(_ctx.$style.distArrow)
          }, null, 8, ["class"]),
          _createVNode(_Transition, { name: "option" }, {
            default: _withCtx(() => [
              (_ctx.isOptionOpen && _ctx.filterStep === _ctx.FilterStep.Dists)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(_ctx.$style.optionWrap)
                  }, [
                    _renderSlot(_ctx.$slots, "dist", {
                      closeFunc: () => (_ctx.isOptionOpen = false)
                    })
                  ], 2))
                : _createCommentVNode("", true)
            ]),
            _: 3
          })
        ], 2))
      : _createCommentVNode("", true),
    (!_ctx.isMobile)
      ? (_openBlock(), _createBlock(_component_Button, {
          key: 1,
          btnName: "Reload",
          btyType: "tooltip",
          title: "更新資料",
          onClick: _ctx.reloadFunc,
          class: _normalizeClass([_ctx.isRotate && _ctx.$style.rotate, _ctx.needUpdate && _ctx.$style.warning])
        }, null, 8, ["onClick", "class"]))
      : _createCommentVNode("", true)
  ], 2))
}