import { AlertType, FilterStep, ModalType } from '@/typings'
import { useStore, mutations } from '@/store/index'
import router from '@/router/index'
import { DateStr, utcTime } from '@/helper/schedule'

const store = useStore()

export const changeUrl = (
  type?: 'home' | 'addTimeslot' | 'addDists' | 'removeDists' | 'custom',
  param?: any
) => {
  let query

  const copyRoute = (obj?: any) => {
    const cntQuery = router.currentRoute.value.query
    return Object.assign({}, cntQuery, obj)
  }

  switch (type) {
    case 'home':
      router.push({ name: 'Home' })
      return
    case 'addTimeslot':
      query = copyRoute({ timeslot: param })
      break
    case 'addDists':
      query = copyRoute({ dist: param.join(',') })
      break
    case 'removeDists':
      query = copyRoute()
      delete query.dist
      break
    case 'custom':
      query = param
      break
    default:
      query = copyRoute()
      delete query.timeslot
  }

  router.push({ query })
}

export const setStorage = (spid?: number, distsId?: number[]) => {
  if (spid && distsId) {
    localStorage.setItem('spid', JSON.stringify(spid))
    localStorage.setItem('dist', JSON.stringify(distsId))
  } else {
    localStorage.clear()
  }
}

export const findFirstDate = () => {
  const dates = store.getters.cntWeekDates
  const data = store.state.mainData.schedule
  const choseDate = (date: string | 'reset') => {
    store.commit(mutations.cntSelect.CHOSE_DATE, date)
  }

  if (store.state.control.isMobile) {
    const arr: number[] = []
    for (const _d in data) {
      arr.push(data[_d].timestamp)
    }
    arr.sort((a, b) => a - b)
    const d = utcTime(arr[0]).format(DateStr)
    choseDate(d)

    return
  }

  if (!dates) return
  for (const i of dates) {
    if (data[i?.dateStr]) {
      choseDate(i.dateStr)
      return
    }
  }

  choseDate('reset')
}

const checkSpid = (spid: number | undefined) => {
  if (!spid) return false
  const storeSports = store.state.mainData.sports
  const validSportList = Object.keys(storeSports).map((s) => Number(s))

  if (!validSportList.includes(spid)) {
    return false
  }

  store.commit(mutations.cntSelect.CHANGE_CNT_SPORT, spid)
  return true
}

const checkDist = (dists: number[] | undefined) => {
  if (!dists) return false
  const storeDists = store.state.mainData.dists
  const validDistList = Object.keys(storeDists).map((d) => Number(d))

  if (dists.length <= 3) {
    for (const i of dists) {
      if (!validDistList.includes(i)) {
        return false
      }
    }
  } else {
    return false
  }

  store.commit(mutations.cntSelect.CHANGE_TEMP_DISTS, dists)
  store.commit(mutations.cntSelect.CHANGE_CNT_DISTS, [...dists])
  return true
}

export const checkValid = (
  spid: number,
  dists: number[],
  func?: (...args: any) => void
) => {
  if (spid && dists) {
    const valid = checkSpid(spid) && checkDist(dists)

    if (valid) {
      store.commit(mutations.control.SET_FIRSTVISIT, false)
      func && func(spid, dists)
    } else {
      store.commit(mutations.control.SET_ALERT, AlertType.WrongCriteria)
      setStorage()
    }
  } else if (checkSpid(spid) || checkDist(dists)) {
    store.commit(mutations.control.SET_MODAL, ModalType.FilterModal)
    store.commit(
      mutations.control.SWITCH_FILTER,
      (spid && FilterStep.Dists) || (dists && FilterStep.Sport)
    )
  } else {
    store.commit(mutations.control.SET_ALERT, AlertType.WrongCriteria)
  }
}
