
import { defineComponent } from 'vue'
import Basketball from '@/assets/sportIcon/basketball.svg'
import Tabletennis from '@/assets/sportIcon/tabletennis.svg'
import Volleyball from '@/assets/sportIcon/volleyball.svg'
import Billiards from '@/assets/sportIcon/billiards.svg'
import Badminton from '@/assets/sportIcon/badminton.svg'
import Squash from '@/assets/sportIcon/squash.svg'
import Golf from '@/assets/sportIcon/golf.svg'
import Classroom from '@/assets/sportIcon/classroom.svg'
import SoccerBilliard from '@/assets/sportIcon/soccerBilliard.svg'

export default defineComponent({
  name: 'Icon',
  components: {
    Basketball,
    Tabletennis,
    Volleyball,
    Billiards,
    Badminton,
    Squash,
    Golf,
    Classroom,
    SoccerBilliard,
  },
  props: {
    sportName: {
      type: String,
      required: true,
    },
  },
})
