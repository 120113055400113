
import { computed, defineComponent } from 'vue'
import { useStore, mutations } from '@/store/index'
import { FilterStep } from '@/typings'

export default defineComponent({
  name: 'Calendar',
  setup() {
    const store = useStore()
    const dateHasData = computed(() => store.getters.dateHasData)
    const getDate = (dateStr: string) => {
      const base = dateStr.split('/')
      return base[1] === '1' ? dateStr : base[1]
    }

    const styleFunc = (date: string) => {
      const selectedDistTag = store.state.currentSelect.selectedDistTag
      const schedule = store.state.mainData.schedule
      if (dateHasData.value(date)) {
        return selectedDistTag === 0 || schedule[date].sum[selectedDistTag]
          ? 'hasData'
          : 'other'
      } else {
        return ''
      }
    }

    return {
      FilterStep,
      getDate,
      styleFunc,
      currentDate: computed(() => store.state.currentSelect.currentDate),
      columnsPart: computed(() => store.state.mainData.columnsPart),
      choseDate: (date: string, week: number) => {
        if (dateHasData.value(date)) {
          store.commit(mutations.cntSelect.CHOSE_DATE, date)
          store.commit(mutations.cntSelect.CHANGE_WEEK, week)
        }
      },
    }
  },
})
