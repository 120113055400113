import {
  Module,
  GetterTree,
  MutationTree,
  ActionContext,
  ActionTree,
  Store as VuexStore,
  CommitOptions,
  DispatchOptions,
} from 'vuex'
import { DateStr, getDays, utcTime } from '@/helper/schedule'
import { arrToObj } from '@/helper/utils'
import {
  ISchedule,
  ISpt2Dist,
  IDist,
  IDist2,
  IDate,
  ICityList,
  IScheduleData,
  HourData,
  VenueCourt,
} from '@/typings'
import API from '@/api'
import { MainState, RootState } from '@/store/types'

const defaultState: MainState = {
  sports: {},
  dists: {},
  distsList: {},
  venuesInfo: {},
  venueList: {},
  columnsPart: [],
  schedule: {},
}

// three weeks
const NUMBER_OF_DAYS = 7 * 3

// Getters types
export type GettersTypes = {
  districtName(state: MainState): (id: number, isFullName?: boolean) => string
  sportName(state: MainState, getters: any, rootState: RootState): string
  venueName(state: MainState): (id: number) => {
    name: string
    shortName: string
  }
  modalInfoSum(
    state: MainState,
    getters: any,
    rootState: RootState
  ): {
    sportName: string
    cityName: string
    distName: string
    venueName: string
  }
  hourlyCourts(
    state: MainState,
    getters: any,
    rootState: RootState
  ): HourData['courts']

  cntWeekDates(state: MainState, getters: any, rootState: RootState): IDate[]
  dateHasData(state: MainState): (date: string) => boolean
  venuesDayData(
    state: MainState,
    getters: any,
    rootState: RootState
  ): (id: number) => VenueCourt
}

//  Getters
export const getters: GetterTree<MainState, RootState> & GettersTypes = {
  districtName:
    (state) =>
    (id, isFullName = false) => {
      const str = state.dists[id]?.districtName
      return isFullName ? str : str.slice(0, 2)
    },

  sportName: (state, getters, rootState) => {
    const cntSpid = rootState.currentSelect.currentSport
    return state.sports[cntSpid]?.sportName
  },
  venueName: (state) => (id) => {
    const str = state.venueList[id]
    return {
      name: str.venueName,
      shortName: str.venueShortName,
    }
  },

  modalInfoSum: (state, getters, rootState) => {
    const cntSpid = rootState.currentSelect.currentSport
    const { districtId, venueId } = rootState.modalData.data

    return {
      sportName: state.sports[cntSpid]?.sportName,
      cityName: state.dists[districtId]?.cityName,
      distName: state.dists[districtId]?.districtName,
      venueName: state.venuesInfo[venueId]?.venueName,
    }
  },
  hourlyCourts: (state, getters, rootState) => {
    const date = rootState.currentSelect.currentDate
    const { start, venueId } = rootState.modalData.data

    return state.schedule[date]?.hours[start][venueId].courts
  },

  cntWeekDates: (state, getters, rootState) => {
    const index = rootState.currentSelect.currentWeek
    return state.columnsPart[index]
  },
  dateHasData: (state) => (date) => state.schedule[date] ? true : false,
  venuesDayData: (state, getters, rootState) => (id) => {
    const date = rootState.currentSelect.currentDate
    return state.schedule[date]?.venues[id]
  },
}

// mutations enums
export enum MutationTypes {
  SET_SPORT2DIST = 'SET_SPORT2DIST',
  SET_DATA = 'SET_DATA',
  SET_DATES = 'SET_DATES',
  SET_VENUEINFO = 'SET_VENUEINFO',
}

// Mutation contracts
export type Mutations<S = MainState> = {
  [MutationTypes.SET_SPORT2DIST](
    state: S,
    payload: {
      sports: MainState['sports']
      dists: MainState['dists']
      list: MainState['distsList']
      venueList: MainState['venueList']
    }
  ): void
  [MutationTypes.SET_VENUEINFO](
    state: S,
    payload: MainState['venuesInfo']
  ): void
  [MutationTypes.SET_DATA](state: S, payload: MainState['schedule']): void
  [MutationTypes.SET_DATES](state: S, payload: MainState['columnsPart']): void
}

const mutations: MutationTree<MainState> & Mutations = {
  [MutationTypes.SET_SPORT2DIST](state, payload) {
    state.sports = payload.sports
    state.dists = payload.dists
    state.distsList = payload.list
    state.venueList = payload.venueList
  },
  [MutationTypes.SET_VENUEINFO](state, payload) {
    state.venuesInfo = payload
  },
  [MutationTypes.SET_DATA](state, payload) {
    state.schedule = payload
  },
  [MutationTypes.SET_DATES](state) {
    state.columnsPart = getDays(NUMBER_OF_DAYS)
  },
}

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload?: Parameters<Mutations[K]>[1],
    option?: { root: boolean }
  ): ReturnType<Mutations[K]>
} & Omit<ActionContext<MainState, RootState>, 'commit'>

export interface Actions {
  ['GET_MAINDATA']({ commit, rootState }: AugmentedActionContext): Promise<void>
  ['GET_INITDATA']({ commit }: AugmentedActionContext): Promise<void>
}

export const actions: ActionTree<MainState, RootState> & Actions = {
  async ['GET_MAINDATA']({ commit, rootState }) {
    const { currentSport: sport_id, currentDistricts: district_ids } =
      rootState.currentSelect

    return await Promise.all([
      API.fetchCourtCount(sport_id, district_ids),
      API.fetchVenuesInfo(sport_id, district_ids),
    ]).then((res) => {
      const [scheduleRaw, venuesInfo] = res

      const scheduleData = scheduleRaw.schedules.reduce(
        (acc: { [date: string]: IScheduleData }, cnt) => {
          const date = utcTime(Number(cnt.date)).format(DateStr)
          const hours: IScheduleData['hours'] = {}
          const venues: IScheduleData['venues'] = {}
          const sum: IScheduleData['sum'] = {}

          cnt.hours.forEach((e) => {
            const v = e.data.reduce((a: { [dist: number]: HourData }, c) => {
              const count = c.timeslotId.length

              sum[c.districtId]
                ? (sum[c.districtId] += count)
                : (sum[c.districtId] = count)

              venues[c.venueId]
                ? (venues[c.venueId].hours[e.hour] = c.timeslotId)
                : (venues[c.venueId] = {
                    districtId: c.districtId,
                    venueId: c.venueId,
                    hours: {
                      [e.hour]: c.timeslotId,
                    },
                  })

              a[c.venueId] = {
                districtId: c.districtId,
                venueId: c.venueId,
                courts: c.timeslotId,
              }

              return a
            }, {})

            hours[e.hour] = v
          })

          acc[date] = {
            timestamp: cnt.date,
            hours,
            sum,
            venues,
          }
          return acc
        },
        {}
      )

      const venueInfoObj = venuesInfo.reduce(
        (acc: ISchedule.VenuesInfo, cnt) => {
          return { ...acc, [cnt.venueId]: cnt }
        },
        {}
      )

      commit(MutationTypes.SET_DATA, scheduleData)
      commit(MutationTypes.SET_VENUEINFO, venueInfoObj)
    })
  },
  async ['GET_INITDATA']({ commit }) {
    commit(MutationTypes.SET_DATES)

    return await Promise.all([
      API.fetchAllDistricts(),
      API.fetchSportToDistrict(),
      API.fetchAllVenues(),
    ]).then((res) => {
      const [resDists, resSpt2Dists, resVenues] = res
      const distsRaw = resDists.districts
      const sportsRaw = resSpt2Dists.sports
      const venuesRaw = resVenues.venues

      const sportList: { [dist: number]: number[] } = {}
      const distsObj: { [id: number]: IDist2 } = {}

      const sportObj = sportsRaw.reduce(
        (acc: { [spid: number]: ISpt2Dist }, cnt) => {
          cnt.districts?.map((d) => {
            sportList[d.districtId]
              ? sportList[d.districtId].push(cnt.sportId)
              : (sportList[d.districtId] = [cnt.sportId])
          })
          const { districts, ...obj } = cnt
          acc[cnt.sportId] = obj
          return acc
        },
        {}
      )
      const distsList = distsRaw.reduce((acc: ICityList, cnt) => {
        const distId = cnt.districtId
        const cityId = cnt.cityId
        distsObj[distId] = { sports: sportList[distId], venues: [], ...cnt }
        acc[cityId]
          ? acc[cityId].dists.push(distId)
          : (acc[cityId] = {
              cityName: cnt.cityName,
              cityId: cityId,
              dists: [distId],
            })
        return acc
      }, {})

      venuesRaw.forEach((v) => {
        distsObj[v.distId].venues?.push(v.venueId)
      })

      Object.keys(distsList).forEach((city) => {
        distsList[city].dists.sort((a, b) => a - b)
      })

      // TODO: delete Taipei city
      if (process.env.NODE_ENV === 'production') delete distsList['TPE']

      const venueList = arrToObj(venuesRaw, 'venueId')

      commit(MutationTypes.SET_SPORT2DIST, {
        sports: sportObj,
        dists: distsObj,
        list: distsList,
        venueList,
      })
    })
  },
}

export type Store<S = MainState> = Omit<
  VuexStore<S>,
  'commit' | 'getters' | 'dispatch'
> & {
  commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
    key: K,
    payload: P,
    options?: CommitOptions
  ): ReturnType<Mutations[K]>
} & {
  getters: {
    [K in keyof GettersTypes]: ReturnType<GettersTypes[K]>
  }
} & {
  dispatch<K extends keyof Actions>(
    key: K,
    payload?: Parameters<Actions[K]>[1],
    options?: DispatchOptions
  ): ReturnType<Actions[K]>
}

export const mainData: Module<MainState, RootState> = {
  state: { ...defaultState },
  getters,
  mutations,
  actions,
}
