
import { defineComponent } from 'vue'
import { marked } from 'marked'
import terms from '@/assets/terms_of_use.md'
import { useStore, mutations } from '@/store/index'
import { ModalType } from '@/typings'
import Button from '@/components/generic/Button.vue'
import ScrollWrap from '@/components/generic/ScrollWrap.vue'

export default defineComponent({
  name: 'Terms',
  components: {
    Button,
    ScrollWrap,
  },

  setup() {
    const markdownHtml = marked.setOptions({ headerIds: false }).parse(terms)
    const store = useStore()

    return {
      ModalType,
      markdownHtml,
      setModal: (type: ModalType) =>
        store.commit(mutations.control.SET_MODAL, type),
    }
  },
})
