
import { useStore } from '@/store/index'
import { defineComponent, ref, PropType, computed, watch } from 'vue'
import ScrollWrap from '@/components/generic/ScrollWrap.vue'
import Arrow from '@/assets/icons/arrow.svg'
import HintIcon from '@/assets/icons/hint.svg'

export default defineComponent({
  name: 'BookingInfo',
  components: {
    ScrollWrap,
    Arrow,
    HintIcon,
  },
  props: {
    selectCourt: {
      type: Function as PropType<(court: any) => void>,
      required: true,
    },
  },

  setup() {
    const store = useStore()
    const allowBooking = ref(true)
    const hasHint = ref(false)
    const courtsData = computed(() => store.state.modalData.courtsData)

    watch(
      () => courtsData.value,
      () => {
        for (const i in courtsData.value) {
          if (!courtsData.value[i].remark.bookingUrl) {
            allowBooking.value = false
            return
          }
          if (courtsData.value[i].clicked) {
            hasHint.value = true
            return
          }
        }
      }
    )

    return {
      allowBooking,
      hasHint,
      courtsData,
      cntCourtId: computed(() => store.state.modalData.bookingInfo.courtId),
      error: computed(() => store.state.modalData.error),
    }
  },
})
