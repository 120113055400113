import { createStore } from 'vuex'
import {
  control,
  MutationTypes as ControlMutations,
  Store as ControlStore,
} from './modules/control'
import {
  currentSelect,
  MutationTypes as CntMutations,
  Store as CntSelectStore,
} from './modules/currentSelect'
import {
  modalData,
  MutationTypes as ModalMutations,
  Store as ModalStore,
} from './modules/modalData'
import {
  mainData,
  MutationTypes as MainMutations,
  Store as MainStore,
} from './modules/mainData'
import { RootState } from '@/store/types'

export type Store = ControlStore<Pick<RootState, 'control'>> &
  CntSelectStore<Pick<RootState, 'currentSelect'>> &
  ModalStore<Pick<RootState, 'modalData'>> &
  MainStore<Pick<RootState, 'mainData'>>

export const mutations = {
  control: ControlMutations,
  cntSelect: CntMutations,
  modal: ModalMutations,
  main: MainMutations,
}

export const store = createStore({
  modules: {
    control,
    currentSelect,
    modalData,
    mainData,
  },
})

export function useStore(): Store {
  return store as Store
}

export default store
