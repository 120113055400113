export enum DaySectionEnum {
  Morning = 'morning',
  Afternoon = 'afternoon',
  Evening = 'evening',
}

export enum ProviderEnum {
  TaipeiApp = 1,
  Official = 2,
  Fit17 = 3,
  CjApp = 4,
}

export interface VenueData {
  venueId: number
  venueName: string
  idleCount: number
  start: number
  end: number
  price: number
}

export interface ModalVenueInfo {
  date: string
  hour: number
  venueId: number
}

export interface HourlyCourts {
  [venueId: string]: HourlyCourt
}

export interface HourlyCourt {
  districtId: number
  venueId: number
  venueShortName: string
  hours: {
    [hour: string]: {
      start: number
      end: number
      courtId: number
      price: number
    }[]
  }
}

export interface VenuesInfo {
  [venueId: number]: VenueInfo
}

export interface VenueInfo {
  sportId: number
  sportName: string
  districtId: number
  venueId: number
  venueName: string
  venueShortName: string
  address: string
  contact: string
  contactTel: string
  email: string
  facilities: string[]
  coord: {
    lat: number
    lng: number
  }
  openingHours: string
  rentInfo: string
  rentState: string
  tel: string
  url: string
  providerId: ProviderEnum
  androidLink: string
  iosLink: string
  webLink: string
}

export interface InfoModal {
  date: string
  start: number
  venueId: number
  districtId: number
}

export interface Fit17Payload {
  branch_id: string
  currency: string
  selected_services: string
  selected_services_namelist: string
  selected_services_pricetotal: string
  selected_services_timetotal: string
  service_url: string
  studio_id: string
}

export interface ICourt {
  courtId: number
  courtName: string
  timeslotId: number
  clicked?: boolean
  status: 'idle' | 'full' | 'expired'
  remark: {
    price: number
    loginUrl: string
    bookingUrl: string
    payload?: Fit17Payload
  }
}

export interface TimeslotData {
  sportId: number
  districtId: number
  venueId: number
  start: number
  end: number
  price: number
  court: ICourt
}
