import { Ref } from 'vue'

export const detectTouchMove = (func: {
  rightFunc: () => void
  leftFunc: () => void
}) => {
  let position = 0
  return {
    setPosition: (e: TouchEvent) => {
      position = e.changedTouches[0].clientX
    },
    execute: (e: TouchEvent) => {
      const shift = e.changedTouches[0].clientX - position

      if (shift < -50) {
        func.rightFunc()
      }
      if (shift > 50) {
        func.leftFunc()
      }
    },
  }
}

export const counter = (control: Ref<boolean>) => {
  let sec = 0
  control.value = false
  const interval = setInterval(() => {
    sec++
    if (sec === 300) {
      control.value = true
      clearInterval(interval)
    }
  }, 1000)
}

export const timeoutFunc = (control: Ref<boolean>, sec = 400) => {
  control.value = true
  const timeoutId = window.setTimeout(() => {
    control.value = false
    window.clearTimeout(timeoutId)
  }, sec)
}

export const debounce = (func: () => void, sec = 400) => {
  let timer: any
  return () => {
    window.clearTimeout(timer)
    timer = window.setTimeout(() => {
      func.apply(this)
    }, sec)
  }
}

export const arrToObj = <T, K extends keyof T>(
  arr: T[],
  id: K
): { [key: number]: T } => {
  return Object.fromEntries(arr.map((e) => [e[id], e]))
}
