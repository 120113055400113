import { Module, MutationTree, Store as VuexStore, CommitOptions } from 'vuex'
import { CurrentSelectState, RootState } from '@/store/types'
// MutationTypes
const defaultState: CurrentSelectState = {
  currentWeek: 0,
  currentDate: '',
  currentSport: 0,
  currentDistricts: [],
  tempDists: [],
  selectedDistTag: 0,
}

// mutations enums
export enum MutationTypes {
  CHANGE_WEEK = 'CHANGE_WEEK',
  CHOSE_DATE = 'CHOSE_DATE',
  CHANGE_CNT_SPORT = 'CHANGE_CNT_SPORT',
  CHANGE_CNT_DISTS = 'CHANGE_CNT_DISTS',
  CHANGE_TEMP_DISTS = 'CHANGE_TEMP_DISTS',
  CHANGE_ALL_SELECT = 'CHANGE_ALL_SELECT',
  RESET_TEMP_DATA = 'RESET_TEMP_DATA',
  SELECT_DIST = 'SELECT_DIST',
}

// Mutation contracts
export type Mutations<S = CurrentSelectState> = {
  [MutationTypes.CHANGE_WEEK](state: S, num: number): void
  [MutationTypes.CHOSE_DATE](state: S, payload: string | 'reset'): void
  [MutationTypes.CHANGE_CNT_SPORT](state: S, sportId: number): void
  [MutationTypes.CHANGE_CNT_DISTS](state: S, districtId: number[]): void
  [MutationTypes.CHANGE_ALL_SELECT](
    state: S,
    payload: 'reset' | { spid: number; dists: number[] }
  ): void
  [MutationTypes.CHANGE_TEMP_DISTS](
    state: S,
    id: number | number[] | 'reset'
  ): void
  [MutationTypes.RESET_TEMP_DATA](state: S): void
  [MutationTypes.SELECT_DIST](state: S, venueId: number): void
}

const mutations: MutationTree<CurrentSelectState> & Mutations = {
  [MutationTypes.CHANGE_WEEK](state, num) {
    if (
      (num > 0 && state.currentWeek <= 1) ||
      (num < 0 && state.currentWeek >= 1)
    ) {
      state.currentWeek += num
    }
  },
  [MutationTypes.CHOSE_DATE](state, payload) {
    state.currentDate = payload === 'reset' ? defaultState.currentDate : payload
  },
  [MutationTypes.CHANGE_CNT_SPORT](state, sportId) {
    state.currentSport = sportId
  },
  [MutationTypes.CHANGE_CNT_DISTS](state, districtsId) {
    state.currentDistricts = districtsId
  },
  [MutationTypes.CHANGE_ALL_SELECT](state, payload) {
    if (payload === 'reset') {
      state.currentSport = defaultState.currentSport
      state.currentDistricts = defaultState.currentDistricts
      state.tempDists = defaultState.tempDists
      state.currentDate = defaultState.currentDate
    } else {
      state.currentSport = payload.spid
      state.currentDistricts = [...payload.dists]
      state.tempDists = [...payload.dists]
    }
  },
  [MutationTypes.CHANGE_TEMP_DISTS](state, id) {
    if (id === 'reset') {
      state.tempDists = []
    } else if (Array.isArray(id)) {
      state.tempDists = id
    } else {
      const isInclude = state.tempDists.includes(id)
      const length = state.tempDists.length

      if (isInclude) {
        const index = state.tempDists.indexOf(id)
        state.tempDists.splice(index, 1)
      }
      if (!isInclude && length < 3) {
        state.tempDists.push(id)
      }
    }
  },
  [MutationTypes.RESET_TEMP_DATA](state) {
    state.tempDists = [...state.currentDistricts]
  },
  [MutationTypes.SELECT_DIST](state, venueId) {
    state.selectedDistTag = venueId
  },
}

// Setup store type
export type Store<S = CurrentSelectState> = Omit<
  VuexStore<S>,
  'commit' | 'getters' | 'dispatch'
> & {
  commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
    key: K,
    payload?: P,
    options?: CommitOptions
  ): ReturnType<Mutations[K]>
}

export const currentSelect: Module<CurrentSelectState, RootState> = {
  state: { ...defaultState },
  mutations,
}
