import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass([_ctx.$style.venue, !_ctx.hasCourt && 'disable'])
    }, _toDisplayString(_ctx.title), 3),
    (_ctx.hasCourt || !_ctx.isMobile)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(_ctx.$style.grid)
        }, [
          (_ctx.venuesDayData)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.emptyTimeslots, (hour) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: hour,
                  class: _normalizeClass([
          _ctx.levelColor(_ctx.venuesDayData.hours[hour]?.length),
          _ctx.isSelected(hour) && 'selected',
          'hover',
        ]),
                  onClick: ($event: any) => (_ctx.venuesDayData.hours[hour]?.length && _ctx.setModalData(hour))
                }, _toDisplayString(hour) + ":00 ", 11, _hoisted_1))
              }), 128))
            : (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: _normalizeClass([_ctx.$style.noData, 'disable'])
              }, "無場地", 2))
        ], 2))
      : _createCommentVNode("", true)
  ], 64))
}