import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "side" }
const _hoisted_2 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LeftSide = _resolveComponent("LeftSide")!
  const _component_Nav = _resolveComponent("Nav")!
  const _component_BookingInfo = _resolveComponent("BookingInfo")!
  const _component_BookingCourt = _resolveComponent("BookingCourt")!
  const _component_VenueInfo = _resolveComponent("VenueInfo")!

  return (_openBlock(), _createElementBlock("div", {
    class: "modal wrap",
    onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"])),
    onTouchstart: _cache[1] || (_cache[1] = (e) => _ctx.touchEvent.setPosition(e)),
    onTouchend: _cache[2] || (_cache[2] = (e) => _ctx.touchEvent.execute(e))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_LeftSide)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.content)
      }, [
        _createVNode(_component_Nav, {
          closeModal: _ctx.closeModal,
          setBooking: _ctx.setBooking,
          isInfoOpen: _ctx.isInfoOpen,
          openInfo: (boo) => (_ctx.isInfoOpen = boo),
          hasCourt: _ctx.hourlyCourts?.length ? true : false
        }, null, 8, ["closeModal", "setBooking", "isInfoOpen", "openInfo", "hasCourt"]),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.$style.bookingWrap)
        }, [
          _createElementVNode("div", {
            style: _normalizeStyle({ marginLeft: _ctx.isBooking ? '-100%' : '0%' })
          }, [
            _createVNode(_component_BookingInfo, { selectCourt: _ctx.selectCourt }, null, 8, ["selectCourt"]),
            _createVNode(_component_BookingCourt, {
              closeBooking: () => _ctx.setBooking(false),
              openLink: _ctx.openLink,
              resetFilter: _ctx.resetFilter,
              closeModal: _ctx.closeModal,
              hasCourt: _ctx.hourlyCourts?.length ? true : false,
              venueInfo: _ctx.venueInfo
            }, null, 8, ["closeBooking", "openLink", "resetFilter", "closeModal", "hasCourt", "venueInfo"])
          ], 4),
          _createVNode(_component_VenueInfo, {
            class: _normalizeClass(_ctx.isInfoOpen ? [_ctx.$style.opened] : [_ctx.$style.closed]),
            style: _normalizeStyle({ bottom: _ctx.isInfoOpen ? '0%' : '100%' }),
            venueInfo: _ctx.venueInfo
          }, null, 8, ["class", "style", "venueInfo"])
        ], 2)
      ], 2)
    ])
  ], 32))
}