
import { defineComponent, computed } from 'vue'
import { useStore, mutations } from '@/store/index'
import { FilterStep } from '@/typings'
import { detectTouchMove } from '@/helper/utils'

export default defineComponent({
  name: 'FilterModal',
  setup() {
    const store = useStore()
    const tempDists = computed(() => store.state.currentSelect.tempDists)
    const filterStep = computed(() => store.state.control.filterStep)
    const isFirstVisit = computed(() => store.state.control.isFirstVisit)

    const districtName = (id: number, boo?: boolean) =>
      store.getters.districtName(id, boo)

    const switchFilter = (step: FilterStep) =>
      store.commit(mutations.control.SWITCH_FILTER, step)

    const distStr = computed(() =>
      tempDists.value.map((id) => districtName(id)).join('、')
    )

    // const touchFunc = {
    //   rightFunc: () => switchFilter(FilterStep.Dists),
    //   leftFunc: () => switchFilter(FilterStep.Sport),
    // }
    // const touchEvent = detectTouchMove(touchFunc)

    return {
      // touchEvent,
      FilterStep,
      filterStep,
      tempDists,
      distStr,
      isFirstVisit,
      isMobile: computed(() => store.state.control.isMobile),
      sportName: computed(() => store.getters.sportName),
      sportStep: computed(() => filterStep.value === FilterStep.Sport),
      districtName,
      switchFilter,
    }
  },
})
