import { Module, MutationTree, Store as VuexStore, CommitOptions } from 'vuex'
import { FilterStep, ModalType, AlertType } from '@/typings'
import { ControlState, RootState } from '@/store/types'

const defaultState: ControlState = {
  isFirstVisit: true,
  isBooking: false,
  isMobile: false,
  filterStep: FilterStep.Sport,
  modalType: ModalType.Loading,
  alertType: AlertType.None,
}

// mutations enums
export enum MutationTypes {
  SET_FIRSTVISIT = 'SET_FIRSTVISIT',
  SET_DEVICE = 'SET_DEVICE',
  SWITCH_MODE = 'SWITCH_MODE',
  SWITCH_FILTER = 'SWITCH_FILTER',
  SET_BOOKING = 'SET_BOOKING',
  SET_MODAL = 'SET_MODAL',
  SET_ALERT = 'SET_ALERT',
}

// Mutation contracts
export type Mutations<S = ControlState> = {
  [MutationTypes.SET_FIRSTVISIT](state: S, boo: boolean): void
  [MutationTypes.SET_DEVICE](state: S, boo: boolean): void
  [MutationTypes.SWITCH_FILTER](state: S, step: FilterStep): void
  [MutationTypes.SET_BOOKING](state: S, boo: boolean): void
  [MutationTypes.SET_MODAL](state: S, type: ModalType): void
  [MutationTypes.SET_ALERT](state: S, type: AlertType): void
}

const mutations: MutationTree<ControlState> & Mutations = {
  [MutationTypes.SET_FIRSTVISIT](state, boo) {
    state.isFirstVisit = boo
  },
  [MutationTypes.SET_DEVICE](state, boo) {
    state.isMobile = boo
    // set dom attribute to indicate mobile view is ON
    document.body[boo ? 'setAttribute' : 'removeAttribute']('data-app-mb', '')
  },
  [MutationTypes.SWITCH_FILTER](state, step) {
    state.filterStep = step
  },
  [MutationTypes.SET_BOOKING](state, boo) {
    state.isBooking = boo
  },
  [MutationTypes.SET_MODAL](state, type) {
    state.modalType = type
  },
  [MutationTypes.SET_ALERT](state, type) {
    state.alertType = type
    state.modalType = ModalType.Alert
  },
}

export type Store<S = ControlState> = Omit<
  VuexStore<S>,
  'commit' | 'getters' | 'dispatch'
> & {
  commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
    key: K,
    payload: P,
    options?: CommitOptions
  ): ReturnType<Mutations[K]>
}

export const control: Module<ControlState, RootState> = {
  state: { ...defaultState },
  mutations,
}
