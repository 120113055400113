import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, Transition as _Transition, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.wrap)
  }, [
    _createElementVNode("div", {
      class: _normalizeClass([_ctx.$style.maskBottom, _ctx.scrollObj.top ? _ctx.$style.show : _ctx.$style.hidden])
    }, null, 2),
    _createElementVNode("div", {
      ref: "listRef",
      onScroll: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.scrollFunc && _ctx.scrollFunc(...args))),
      class: _normalizeClass([_ctx.scrollable && _ctx.$style.scrollable, _ctx.$style.content])
    }, [
      _createVNode(_Transition, null, {
        default: _withCtx(() => [
          (_ctx.hasLoading)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(_ctx.$style.loading)
              }, [
                _createVNode(_component_Loading)
              ], 2))
            : _renderSlot(_ctx.$slots, "default", { key: 1 })
        ]),
        _: 3
      })
    ], 34),
    _createElementVNode("div", {
      class: _normalizeClass([_ctx.$style.maskTop, _ctx.scrollObj.bottom ? _ctx.$style.show : _ctx.$style.hidden])
    }, null, 2)
  ], 2))
}