
import { defineComponent, computed, PropType } from 'vue'
import { levelColor } from '@/helper/schedule'
import { useStore } from '@/store/index'
import { IVenue } from '@/typings'

export default defineComponent({
  name: 'DayCourts',
  props: {
    venueId: {
      type: Number,
      required: true,
    },
    selectVenue: {
      type: Function as PropType<
        (modatData: {
          date: string
          start: number
          districtId: number
          venueId: number
        }) => void
      >,
      required: true,
    },
    emptyTimeslots: {
      type: Array as PropType<number[]>,
      required: true,
    },
  },

  setup(props) {
    const store = useStore()
    const venuesDayData = computed(() =>
      store.getters.venuesDayData(props.venueId)
    )

    const hasCourt = computed(() => venuesDayData.value)
    const title = computed(() => {
      const venuesInfo = store.state.mainData.venuesInfo[props.venueId]
      const distName = store.getters.districtName(venuesInfo?.districtId, true)
      const venueName = venuesInfo?.venueName

      return `${distName}．${venueName}${hasCourt.value ? '' : '（無場地）'}`
    })

    const setModalData = (hour: number) => {
      const date = store.state.currentSelect.currentDate
      const _o = venuesDayData.value
      const obj = {
        date: date,
        start: hour,
        districtId: _o.districtId,
        venueId: _o.venueId,
      }
      props.selectVenue(obj)
    }

    return {
      isMobile: computed(() => store.state.control.isMobile),
      venuesDayData,
      isSelected: (hour: number) => {
        const date = store.state.currentSelect.currentDate
        return store.getters.isSelected(date, hour, props.venueId)
      },
      levelColor,
      setModalData,
      title,
      hasCourt,
    }
  },
})
