import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.bookmarkWrap)
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.distArr, (dist, i) => {
      return (_openBlock(), _createElementBlock("div", {
        key: i,
        class: _normalizeClass([
        _ctx.$style.bookmark,
        (_ctx.distArr.length === 1 || _ctx.selectedDistTag === dist) && _ctx.$style.selected,
      ]),
        onClick: () => _ctx.selectDistrict(dist)
      }, _toDisplayString(dist === 0 ? '全部' : _ctx.districtName(dist)), 11, _hoisted_1))
    }), 128)),
    _createElementVNode("span", {
      class: _normalizeClass(_ctx.$style.bar),
      style: _normalizeStyle(_ctx.barStyle)
    }, null, 6)
  ], 2))
}