
import { mutations, useStore } from '@/store/index'
import { defineComponent, PropType, computed } from 'vue'
import Button from '@/components/generic/Button.vue'

export default defineComponent({
  name: 'Nav',
  components: {
    Button,
  },
  props: {
    closeModal: {
      type: Function as PropType<() => void>,
      required: true,
    },
    setBooking: {
      type: Function as PropType<(boo: boolean) => void>,
      required: true,
    },
    isInfoOpen: {
      type: Boolean,
      required: true,
    },
    openInfo: {
      type: Function as PropType<(boo: boolean) => void>,
      required: true,
    },
    hasCourt: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const store = useStore()
    const modalData = computed(() => store.state.modalData)

    const backFunc = () => {
      if (modalData.value.error.status !== 'expired' && props.hasCourt) {
        props.setBooking(false), props.openInfo(false)
        store.commit(mutations.modal.SET_MODAL_ERROR, {
          status: 'idle',
          courtName: '',
        })
      }
    }

    // const courtName = computed(() => {
    //   const venueId = modalData.value.data.venueId
    //   const str = modalData.value.bookingInfo.courtName
    //   return venueId === 32 ? str.split(' ')[0] : str
    // })

    return {
      modalData,
      isMobile: computed(() => store.state.control.isMobile),
      isBooking: computed(() => store.state.control.isBooking),
      modalInfoSum: computed(() => store.getters.modalInfoSum),
      courtPrice: computed(() => store.getters.courtPrice),
      backFunc,
    }
  },
})
