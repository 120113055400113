import { createApp } from 'vue'
import App from '@/App.vue'
import { store } from '@/store'
import router from '@/router/index'
// import './registerServiceWorker'

const app = createApp(App)

app.use(router).use(store)
app.mount('#app')
