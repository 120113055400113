
import { defineComponent, ref, nextTick, watch } from 'vue'
import { useStore } from '@/store/index'
import Loading from '@/assets/icons/loading.svg'

export default defineComponent({
  name: 'ScrollWrap',
  components: { Loading },
  props: {
    hasLoading: {
      type: Boolean,
    },
  },
  setup() {
    const store = useStore()
    const listRef = ref<HTMLElement>()
    const scrollable = ref(true)
    const scrollObj = ref({
      top: false,
      bottom: true,
    })

    const scrollFunc = () => {
      if (!listRef.value) return
      const {
        scrollTop: pos,
        clientHeight: max,
        scrollHeight: total,
      } = listRef.value

      scrollObj.value.top = pos > 3
      scrollObj.value.bottom = max + pos < total - 3
    }

    const updateListHeight = async () => {
      await nextTick()
      if (!listRef.value) return
      const { clientHeight: max, scrollHeight: total } = listRef.value
      if (total === max) {
        scrollable.value = false
        scrollObj.value.bottom = false
      }
    }

    watch(
      () => store.state.modalData.courtsData,
      () => {
        updateListHeight()
      }
    )

    return {
      listRef,
      scrollObj,
      scrollable,
      scrollFunc,
    }
  },
})
