import dayjs, { Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import isoWeek from 'dayjs/plugin/isoWeek'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { IDate } from '@/typings'
import 'dayjs/locale/zh-tw'

dayjs.extend(customParseFormat)
dayjs.extend(utc)
dayjs.extend(isoWeek)

export const DateStr = 'M/D'

// Taipei: utc + 8; utc offset: = 8 * 60 = 480
const inTaipeiTimezone = dayjs().utcOffset() === 480

export const utcTime = (time?: number | string) => {
  const p = typeof time === 'number' ? time * 1000 : time
  return dayjs(p).utcOffset(8)
}

export const convertTime = (time: number) => {
  if (inTaipeiTimezone) {
    const _d = new Date(time * 1000)
    return {
      date: `${_d.getMonth() + 1}/${_d.getDate()}`,
      hour: _d.getHours(),
    }
  } else {
    const _d = utcTime(time)
    return {
      date: _d.format(DateStr),
      hour: _d.hour(),
    }
  }
}

export const getDateStr = (date: string) => {
  const year = utcTime().year()
  const d = utcTime(`${year}/${date}`).locale('zh-tw').format('M月D日 dddd')

  return date ? d : '選擇時間'
}

export const getDays = (num: number) => {
  const startDate = utcTime().startOf('isoWeek')
  const _days: Dayjs[] = []
  for (let i = 0; i < num; i++) {
    _days.push(startDate.add(i, 'day'))
  }

  return _days.reduce((a: IDate[][], _d, i) => {
    const w = Math.floor(i / 7)
    const o = {
      weekday: _d.locale('zh-tw').format('dd'),
      dateStr: _d.format(DateStr),
    }
    a[w] ? a[w].push(o) : (a[w] = [o])

    return a
  }, [])
}

export const levelColor = (idleCount: number) => {
  if (idleCount >= 5) return 'level_high'
  if (idleCount >= 3) return 'level_mid'
  if (idleCount > 0) return 'level_low'
  return 'level_none'
}
