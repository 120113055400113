
import { defineComponent, computed, onMounted, ref, PropType } from 'vue'
import { useStore, mutations } from '@/store/index'
import { ModalType } from '@/typings'
import Filter from '@/components/Filter.vue'
import Logo from '@/assets/logos/logo-color-w.svg'
import Button from '@/components/generic/Button.vue'
import Bookmark from '@/components/Bookmark.vue'

export default defineComponent({
  name: 'Header',
  props: {
    needUpdate: {
      type: Boolean,
      required: true,
    },

    getTimeTableData: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },
  components: {
    Filter,
    Logo,
    Button,
    Bookmark,
  },

  setup() {
    const store = useStore()
    const isShrink = ref(false)
    const isMobile = computed(() => store.state.control.isMobile)

    onMounted(() => {
      if (isMobile.value) return
      const mainRef = document.getElementsByTagName('main')[0]

      const printScroll = () => {
        const scrollTop = mainRef.scrollTop
        const scrollRange = mainRef.scrollHeight - mainRef.offsetHeight

        if (scrollTop > 50 && isShrink.value === false && scrollRange >= 150) {
          isShrink.value = true
        }
        if (scrollTop < 50 && isShrink.value === true) {
          isShrink.value = false
        }
      }

      mainRef?.addEventListener(
        'scroll',
        () => {
          printScroll()
        },
        false
      )
    })

    return {
      isShrink,
      isMobile,
      ModalType,
      setModal: (type: ModalType) =>
        store.commit(mutations.control.SET_MODAL, type),
    }
  },
})
