import axios, { Method } from 'axios'
import { Request, Methods } from '@/typings/api'
import { stringify } from 'qs'

const domain = process.env.VUE_APP_DOMAIN
const version = 'v2'
const base = `${domain}/api/${version}`

const request = async (
  method: Method,
  endpoint: string,
  data: Request.Data | null = null,
  options: Request.Options = {}
) => {
  const { cancelToken = null, authorize = true } = options
  const getData = method === Methods.Get ? data : null
  const postData = method === Methods.Post ? data : null
  const url = `${base}${endpoint}`
  const req: Request.Config = {
    url,
    method,
    paramsSerializer: (params) => {
      // convert normal array to string array with quote 👉 "[1,2,3]"
      Object.keys(params).forEach((p) => {
        if (Array.isArray(params[p])) {
          params[p] = `[${params[p]}]`
        }
      })
      return stringify(params)
    },
  }

  if (getData) {
    req.params = getData
  }

  if (postData) {
    req.data = postData
  }

  if (cancelToken) {
    req.cancelToken = cancelToken
  }

  const { data: json } = await axios(req)
  return json
}

export default request
