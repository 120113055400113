
import { defineComponent, computed, PropType, ref, onMounted } from 'vue'
import { useStore, mutations } from '@/store/index'
import { FilterStep } from '@/typings'
import { timeoutFunc } from '@/helper/utils'
import Button from '@/components/generic/Button.vue'

export default defineComponent({
  name: 'OptionsDist',
  components: { Button },
  props: {
    submit: {
      type: Function as PropType<() => void>,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    closeFunc: {
      type: Function as PropType<() => void>,
    },
  },

  setup() {
    const store = useStore()
    const tempDists = computed(() => store.state.currentSelect.tempDists)
    const cntSport = computed(() => store.state.currentSelect.currentSport)
    const distsList = computed(() => store.state.mainData.distsList)
    const dists = computed(() => store.state.mainData.dists)
    const cntCity = ref<string>(Object.keys(distsList.value)[0])
    const isShake = ref(false)
    const distCount = computed(() =>
      tempDists.value.reduce(
        (
          a: {
            [city: string]: number
          },
          c
        ) => {
          const id = dists.value[c].cityId
          a[id] ? (a[id] += 1) : (a[id] = 1)
          return a
        },
        {}
      )
    )

    const isDisable = (districtId: number) => {
      const sports = dists.value[districtId].sports
      if (!sports) return true
      const hasSport = sports.includes(cntSport.value)
      const selected = tempDists.value.includes(districtId)

      if (selected && hasSport) {
        return false
      } else if (!hasSport) {
        return true
      }
    }

    const changeTempDists = (id: number) => {
      const preTemp = [...tempDists.value]
      store.commit(mutations.cntSelect.CHANGE_TEMP_DISTS, id)

      if (preTemp.length === 3 && tempDists.value.length === 3) {
        timeoutFunc(isShake)
        return
      }
    }

    return {
      cntCity,
      tempDists,
      distsList,
      distCount,
      isShake,
      isDisable,
      changeTempDists,
      FilterStep,
      filterStep: computed(() => store.state.control.filterStep),
      districtName: computed(() => store.getters.districtName),
      sportName: computed(() => store.getters.sportName),
    }
  },
})
