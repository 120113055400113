
import { defineComponent, computed, PropType } from 'vue'
import Icon from '@/components/generic/Icon.vue'
import { useStore, mutations } from '@/store/index'
import { FilterStep } from '@/typings'

export default defineComponent({
  name: 'OptionsSport',
  components: {
    Icon,
  },
  props: {
    submit: {
      type: Function as PropType<() => void>,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    closeFunc: {
      type: Function as PropType<() => void>,
    },
  },
  setup(props) {
    const store = useStore()
    const isFirstVisit = computed(() => store.state.control.isFirstVisit)

    const sportList = computed(() => {
      const tempDists = store.state.currentSelect.tempDists
      const dists = store.state.mainData.dists

      if (isFirstVisit.value || !tempDists) return []

      return tempDists?.reduce((acc: number[], cnt: number) => {
        const sports = dists[cnt].sports
        return sports ? [...acc, ...sports] : acc
      }, [])
    })

    const changeSport = (id: number) => {
      store.commit(mutations.cntSelect.CHANGE_CNT_SPORT, id)
      const tempDists = store.state.currentSelect.tempDists
      if (isFirstVisit.value) {
        store.commit(mutations.control.SWITCH_FILTER, FilterStep.Dists)
        return
      }
      if (tempDists.length > 0) {
        props.submit()
        props.closeFunc && props.closeFunc()
      }
    }

    return {
      FilterStep,
      isFirstVisit,
      sportList,
      cntSport: computed(() => store.state.currentSelect.currentSport),
      sports: computed(() => store.state.mainData.sports),
      isMobile: computed(() => store.state.control.isMobile),
      filterStep: computed(() => store.state.control.filterStep),
      changeSport,
    }
  },
})
