import { renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, Transition as _Transition, createVNode as _createVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Teleport as _Teleport } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createVNode(_Transition, null, {
      default: _withCtx(() => [
        (_ctx.modalType)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "blurLayer",
              onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.closeFunc && _ctx.closeFunc(...args)), ["stop"]))
            }, [
              _createVNode(_Transition, { mode: "out-in" }, {
                default: _withCtx(() => [
                  (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.modalType), _normalizeProps(_guardReactiveProps(_ctx.cntProps(_ctx.modalType))), {
                    sport: _withCtx(() => [
                      _renderSlot(_ctx.$slots, "sport")
                    ]),
                    dist: _withCtx(() => [
                      _renderSlot(_ctx.$slots, "dist")
                    ]),
                    _: 3
                  }, 16))
                ]),
                _: 3
              })
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 3
    })
  ]))
}