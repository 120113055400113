
import { defineComponent, computed, PropType, ref, watch } from 'vue'
import { useStore, mutations } from '@/store/index'
import { FilterStep, ModalType } from '@/typings'
import { debounce } from '@/helper/utils'
import { changeUrl } from '@/helper/home'
import Button from '@/components/generic/Button.vue'
import Arrow from '@/assets/icons/arrow.svg'
import Search from '@/assets/icons/search-16.svg'
import Close from '@/assets/icons/close.svg'

export default defineComponent({
  name: 'Filter',
  props: {
    needUpdate: {
      type: Boolean,
      required: true,
    },

    getTimeTableData: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },
  components: {
    Button,
    Arrow,
    Search,
    Close,
  },
  setup(props) {
    const store = useStore()
    const isOptionOpen = ref(false)
    const isRotate = ref(false)
    const openSelector = (step: FilterStep) => {
      isOptionOpen.value = true
      store.commit(mutations.control.SWITCH_FILTER, step)
    }
    const cntDists = computed(() => store.state.currentSelect.currentDistricts)

    const reloadFunc = debounce(async () => {
      isRotate.value = true
      await props.getTimeTableData()
      isRotate.value = false
    })

    const removeDist = (id: number) => {
      const arr = [...cntDists.value].filter((i) => i !== id)
      store.commit(mutations.cntSelect.CHANGE_TEMP_DISTS, id)

      if (!isOptionOpen.value) {
        store.commit(mutations.cntSelect.CHANGE_CNT_DISTS, arr)
        localStorage.setItem('dist', JSON.stringify(arr))
        changeUrl('addDists', arr)
      }

      if (arr.length === 0) {
        changeUrl('removeDists')
        store.commit(mutations.main.SET_DATA, {})
        openSelector(FilterStep.Dists)
      }
    }

    const clickEvent = () => {
      isOptionOpen.value = false
      store.commit(mutations.cntSelect.RESET_TEMP_DATA)
      window.removeEventListener('click', clickEvent)
    }

    watch(isOptionOpen, () => {
      if (isOptionOpen.value && cntDists.value.length) {
        window.addEventListener('click', clickEvent, { passive: true })
      }
    })

    return {
      ModalType,
      FilterStep,
      distsId: computed(() => {
        const tempDists = store.state.currentSelect.tempDists
        return isOptionOpen.value ? tempDists : cntDists.value
      }),
      isRotate,
      isOptionOpen,
      removeDist,
      reloadFunc,
      openSelector,
      isMobile: computed(() => store.state.control.isMobile),
      filterStep: computed(() => store.state.control.filterStep),
      sportName: computed(() => store.getters.sportName),
      districtName: computed(() => store.getters.districtName),
      setModal: (type: ModalType) =>
        store.commit(mutations.control.SET_MODAL, type),
    }
  },
})
