import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.options)
    }, [
      _createElementVNode("ul", {
        class: _normalizeClass([
        _ctx.$style.sports,
        {
          [_ctx.$style.multiColor]: _ctx.isFirstVisit || _ctx.isMobile,
        },
      ])
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sports, (sport) => {
          return (_openBlock(), _createElementBlock("li", {
            key: sport.sportId,
            class: _normalizeClass([
          sport.sportId === _ctx.cntSport && _ctx.$style.selected,
          !_ctx.isFirstVisit && !_ctx.sportList.includes(sport.sportId) && 'disable',
        ]),
            onClick: _withModifiers(() => _ctx.changeSport(sport.sportId), ["stop"])
          }, [
            _createVNode(_component_Icon, {
              sportName: sport.sportNameEn
            }, null, 8, ["sportName"]),
            _createElementVNode("span", null, _toDisplayString(sport.sportName), 1)
          ], 10, _hoisted_1))
        }), 128))
      ], 2)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.submit)
    }, [
      _createElementVNode("p", null, [
        _createElementVNode("small", null, _toDisplayString(_ctx.isFirstVisit ? ' ' : '所選地區僅含部分運動項目'), 1)
      ])
    ], 2)
  ], 64))
}