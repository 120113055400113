import { Methods } from '@/typings/api'

import request from './request'
import { ResponseType } from '@/typings/api/Response'

const API: ResponseType = {
  fetchSportToDistrict: () => request(Methods.Get, '/search/districts/sport'),
  fetchAllDistricts: () => request(Methods.Get, '/search/districts'),
  fetchAllVenues: () => request(Methods.Get, '/search/venues'),
  fetchCrowdCount: () => request(Methods.Get, '/statistics/people'),
  fetchVenuesInfo: (sport_id, district_ids) =>
    request(Methods.Get, '/search/info', {
      sport_id,
      district_ids,
    }),
  fetchCourtCount: (sport_id, district_ids) =>
    request(Methods.Get, '/search/courts/schedule', {
      sport_id,
      district_ids,
    }),
  fetchTimetable: (obj) =>
    request(Methods.Get, '/search/courts/timetable', obj),
  fetchCourtData: (id) =>
    request(Methods.Get, '/search/courts/share', {
      id,
    }),
  fetchCourtsHint: (ids) => {
    return request(Methods.Get, '/hint/courts', { ids })
  },
  postClickedCourt: (timeslotId) =>
    request(Methods.Post, '/hint/courts', { timeslotId }),
  postFit17Data: (link, obj) => {
    const w = window.open('', '_blank', 'noopenner')
    if (!w) return
    const form = w.document.createElement('form')
    form.method = 'post'
    form.action = link

    for (const i in obj) {
      const key = i as keyof typeof obj
      const hiddenField = document.createElement('input')
      hiddenField.type = 'hidden'
      hiddenField.name = key
      hiddenField.value = obj[key]
      form.appendChild(hiddenField)
    }

    w.document.body.appendChild(form)
    form.submit()
  },
}

export default API
