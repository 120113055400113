
import { defineComponent, PropType, watch, onMounted, computed } from 'vue'
import { ModalType, FilterStep } from '@/typings'
import { useStore, mutations } from '@/store/index'
import Terms from '@/components/Modals/Terms.vue'
import Directions from '@/components/Modals/Directions.vue'
import FilterModal from '@/components/Modals/FilterModal.vue'
import InfoModal from '@/components/Modals/InfoModal.vue'
import Alert from '@/components/Modals/Alert.vue'
import Loading from '@/components/Modals/Loading.vue'
import Crowd from '@/components/Modals/Crowd.vue'
import { changeUrl } from '@/helper/home'

export default defineComponent({
  name: 'Modal',
  components: {
    Directions,
    Terms,
    FilterModal,
    InfoModal,
    Alert,
    Loading,
    Crowd,
  },
  props: {
    tempToStore: {
      type: Function as PropType<(spid?: number, dists?: number[]) => void>,
      required: true,
    },
  },

  setup(props) {
    const store = useStore()
    const modalType = computed(() => store.state.control.modalType)

    const resetFilter = () => {
      props.tempToStore()
      store.commit(mutations.control.SET_FIRSTVISIT, true)
      store.commit(mutations.control.SWITCH_FILTER, FilterStep.Sport)
      store.commit(mutations.control.SET_MODAL, ModalType.FilterModal)
    }

    const closeFunc = () => {
      const isFirstVisit = store.state.control.isFirstVisit

      switch (modalType.value) {
        case ModalType.FilterModal:
          if (isFirstVisit) return
          store.commit(mutations.cntSelect.RESET_TEMP_DATA)
          break

        case ModalType.Directions:
          if (isFirstVisit) {
            resetFilter()
            return
          }
          break

        case ModalType.InfoModal:
          changeUrl()
          break

        case ModalType.Loading:
        case ModalType.Alert:
          return
      }
      store.commit(mutations.control.SET_MODAL, ModalType.None)
    }

    const cntProps = (type: ModalType) => {
      switch (type) {
        case ModalType.Alert:
          return {
            resetFilter,
            tempToStore: props.tempToStore,
          }

        case ModalType.InfoModal:
        case ModalType.Directions:
          return { resetFilter }

        case ModalType.None:
        case ModalType.Loading:
        case ModalType.FilterModal:
        case ModalType.Terms:
        default:
          return
      }
    }

    const isFireFox = navigator.userAgent.indexOf('Firefox') > -1
    const setBlurEffect = (boo: boolean) => {
      const appDom = document.getElementById('app')
      if (!appDom) return
      appDom.style.filter = boo ? 'blur(2px)' : 'blur(0px)'
    }

    watch(modalType, () => {
      isFireFox && setBlurEffect(modalType.value ? true : false)
    })

    onMounted(() => {
      isFireFox && setBlurEffect(true)
    })

    return {
      modalType,
      cntProps,
      closeFunc,
    }
  },
})
