import {
  Module,
  MutationTree,
  Store as VuexStore,
  CommitOptions,
  GetterTree,
  ActionContext,
  ActionTree,
  DispatchOptions,
} from 'vuex'
import { ISchedule } from '@/typings'
import { ModalState, RootState } from '@/store/types'
import API from '@/api'

const getDefaultState = (): ModalState => {
  return {
    data: {
      date: '',
      start: 0,
      venueId: 0,
      districtId: 0,
    },
    subData: {
      end: 0,
      priceRange: '',
    },
    courtsData: {},
    bookingInfo: {
      courtId: 0,
      courtName: '',
      timeslotId: 0,
      status: 'idle',
      remark: {
        price: 0,
        loginUrl: '',
        bookingUrl: '',
      },
    },
    error: {
      status: 'idle',
      courtName: '',
    },
  }
}
const defaultState = getDefaultState()

export type GettersTypes = {
  isSelected(
    state: ModalState
  ): (date: string, hour: number, venueId: number) => boolean
  courtPrice: (
    state: ModalState,
    getters: GettersTypes,
    rootState: RootState
  ) => any
}

export const getters: GetterTree<ModalState, RootState> & GettersTypes = {
  isSelected: (state) => (date, hour, venueId) => {
    return (
      state.data.date === date &&
      state.data.start === hour &&
      state.data.venueId === venueId
    )
  },

  courtPrice: (state, getters, rootState) => {
    const courtPrice = state.bookingInfo.remark.price.toString()
    const isBooking = rootState.control.isBooking
    return isBooking ? courtPrice : state.subData.priceRange
  },
}

export enum MutationTypes {
  SET_MODAL_DATA = 'SET_MODAL_DATA',
  SET_MODAL_SUBDATA = 'SET_MODAL_SUBDATA',
  SET_MODAL_BOOKINGINFO = 'SET_MODAL_BOOKINGINFO',
  SET_MODAL_ERROR = 'SET_MODAL_ERROR',
  SET_COURTSDATA = 'SET_COURTSDATA',
  RESET = 'RESET',
}

export type Mutations<S = ModalState> = {
  [MutationTypes.SET_MODAL_DATA](state: S, payload: ISchedule.InfoModal): void
  [MutationTypes.SET_MODAL_SUBDATA](
    state: S,
    payload: ModalState['subData']
  ): void
  [MutationTypes.SET_MODAL_BOOKINGINFO](
    state: S,
    payload: ModalState['bookingInfo']
  ): void
  [MutationTypes.SET_MODAL_ERROR](
    state: S,
    payload: {
      status: ModalState['error']['status']
      courtName: string
    }
  ): void
  [MutationTypes.SET_COURTSDATA](
    state: S,
    payload: ModalState['courtsData']
  ): void
  [MutationTypes.RESET](state: S, payload: string): void
}

const mutations: MutationTree<ModalState> & Mutations = {
  [MutationTypes.SET_MODAL_DATA](state, payload) {
    state.data = payload
  },
  [MutationTypes.SET_MODAL_SUBDATA](state, payload) {
    state.subData = payload
  },
  [MutationTypes.SET_MODAL_BOOKINGINFO](state, payload) {
    state.bookingInfo = payload
  },
  [MutationTypes.SET_MODAL_ERROR](state, payload) {
    state.error = payload
  },
  [MutationTypes.SET_COURTSDATA](state, payload) {
    state.courtsData = payload
  },
  [MutationTypes.RESET](state, payload) {
    Object.assign(state, getDefaultState())
  },
}

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload?: Parameters<Mutations[K]>[1],
    option?: { root: boolean }
  ): ReturnType<Mutations[K]>
} & Omit<ActionContext<ModalState, RootState>, 'commit'>

export interface Actions {
  ['GET_DETAIL']({ commit }: AugmentedActionContext): void
}

export const actions: ActionTree<ModalState, RootState> & Actions = {
  async ['GET_DETAIL']({ commit, rootState, state }) {
    const { date, start, venueId } = state.data
    const spid = rootState.currentSelect.currentSport
    const timestamp = rootState.mainData.schedule[date].timestamp
    const timeslots =
      rootState.mainData.schedule[date].hours[start][venueId].courts
    const obj = {
      timestamp,
      sport_id: spid,
      timeslot_ids: timeslots,
      venue_id: venueId,
    }

    await API.fetchTimetable(obj).then((res) => {
      const prices: number[] = []
      const courtsData = res.timetables.reduce(
        (a: { [court: number]: ISchedule.ICourt }, c) => {
          const { courtId, courtName, timeslotId, status, remark, clicked } = c

          if (prices.length === 0 || !prices.includes(c.remark.price))
            prices.push(c.remark.price)

          const name = venueId === 32 ? courtName.split(' ')[0] : courtName

          a[courtId] = {
            courtId,
            courtName: name,
            timeslotId,
            clicked,
            status,
            remark,
          }

          return a
        },
        {}
      )

      const end = new Date(res.timetables[0]?.end * 1000).getHours()
      const priceRange =
        prices.length > 1
          ? prices.sort((a, b) => a - b).join(' / ')
          : prices[0]?.toString()

      commit(MutationTypes.SET_COURTSDATA, courtsData)
      commit(MutationTypes.SET_MODAL_SUBDATA, {
        end,
        priceRange,
      })
    })
  },
}

export type Store<S = ModalState> = Omit<
  VuexStore<S>,
  'commit' | 'getters' | 'dispatch'
> & {
  commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
    key: K,
    payload: P,
    options?: CommitOptions
  ): ReturnType<Mutations[K]>
} & {
  getters: {
    [K in keyof GettersTypes]: ReturnType<GettersTypes[K]>
  }
} & {
  dispatch<K extends keyof Actions>(
    key: K,
    payload?: Parameters<Actions[K]>[1],
    options?: DispatchOptions
  ): ReturnType<Actions[K]>
}

export const modalData: Module<ModalState, RootState> = {
  state: { ...defaultState },
  getters,
  mutations,
  actions,
}
