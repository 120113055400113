
import { computed, defineComponent } from 'vue'
import { useStore, mutations } from '@/store/index'
import { ModalType, _global } from '@/typings'
import DayCourts from '@/components/schedule/DayCourts.vue'

export default defineComponent({
  name: 'ScheduleDetail',
  components: {
    DayCourts,
  },
  setup() {
    const store = useStore()

    const selectVenue = (obj: {
      date: string
      start: number
      districtId: number
      venueId: number
    }) => {
      store.commit(mutations.modal.RESET, 'reset')
      store.commit(mutations.modal.SET_MODAL_DATA, obj)
      store.dispatch('GET_DETAIL')
      store.commit(mutations.cntSelect.CHOSE_DATE, obj.date)
      store.commit(mutations.control.SET_BOOKING, false)
      store.commit(mutations.control.SET_MODAL, ModalType.InfoModal)
    }

    const cntDists = computed(() => {
      const { currentDistricts, selectedDistTag } = store.state.currentSelect
      const { isMobile } = store.state.control
      return isMobile && selectedDistTag ? [selectedDistTag] : currentDistricts
    })

    const venuesId = computed(() => {
      const { venuesInfo } = store.state.mainData
      return Object.keys(venuesInfo).map((e) => Number(e))
    })

    const distAndVenues = computed(() => {
      const { dists } = store.state.mainData
      return cntDists.value.reduce((a: { [dist: number]: number[] }, c) => {
        const availiableVenues: number[] = []
        dists[c].venues.forEach((e) => {
          venuesId.value.includes(e) && availiableVenues.push(e)
        })

        a[c] = availiableVenues
        return a
      }, {})
    })

    return {
      selectVenue,
      cntDists,
      distAndVenues,
      emptyTimeslots: Array.from({ length: 16 }, (_, i) => i + 6),
      sportName: computed(() => store.getters.sportName),
      distName: computed(() => store.getters.districtName),
    }
  },
})
