import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["data-day", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Arrow = _resolveComponent("Arrow")!
  const _component_TimeslotBasic = _resolveComponent("TimeslotBasic")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.schedule)
  }, [
    _createElementVNode("span", {
      class: _normalizeClass(_ctx.$style.weekBtn),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeWeek(-1))),
      style: _normalizeStyle({
        opacity: _ctx.currentWeek === 0 ? '0.2' : '1',
      })
    }, [
      _createVNode(_component_Arrow, {
        style: _normalizeStyle({
          transform: 'rotate(90deg)',
        })
      }, null, 8, ["style"])
    ], 6),
    _createElementVNode("span", {
      class: _normalizeClass(_ctx.$style.weekBtn),
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeWeek(1))),
      style: _normalizeStyle({
        opacity: _ctx.currentWeek === 2 ? '0.2' : '1',
      })
    }, [
      _createVNode(_component_Arrow, {
        style: _normalizeStyle({
          transform: 'rotate(-90deg)',
        })
      }, null, 8, ["style"])
    ], 6),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cntWeekDates, (date, i) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: i }, [
        _createElementVNode("div", {
          class: _normalizeClass([
          _ctx.$style.weekday,
          date.dateStr === _ctx.currentDate && _ctx.$style.selected,
        ]),
          "data-day": i + 1,
          onClick: ($event: any) => (_ctx.choseDate(date.dateStr))
        }, _toDisplayString(date.dateStr) + " " + _toDisplayString(date.weekday), 11, _hoisted_1),
        _createVNode(_component_TimeslotBasic, {
          class: _normalizeClass(date.dateStr === _ctx.currentDate && _ctx.$style.selected),
          dateStr: date.dateStr,
          "data-day": i + 1,
          onClick: ($event: any) => (_ctx.choseDate(date.dateStr))
        }, null, 8, ["class", "dateStr", "data-day", "onClick"])
      ], 64))
    }), 128)),
    (_ctx.cntDists.length === 0)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass([_ctx.$style.noData, 'disable'])
        }, " 請選擇場地 ", 2))
      : _createCommentVNode("", true)
  ], 2))
}