
import { defineComponent, computed } from 'vue'
import { useStore, mutations } from '@/store/index'
export default defineComponent({
  name: 'FilterBar',
  setup() {
    const store = useStore()
    const distArr = computed(() => {
      const cntDist = store.state.currentSelect.currentDistricts
      return cntDist.length > 1 ? [0, ...cntDist] : cntDist
    })
    const selectedDistTag = computed(
      () => store.state.currentSelect.selectedDistTag
    )

    const barStyle = computed(() => {
      const index = distArr.value.findIndex((n) => n === selectedDistTag.value)
      const _w = 100 / distArr.value.length
      return {
        left: `${index < 0 ? 0 : index * _w}%`,
        width: `${_w}%`,
      }
    })

    return {
      distArr,
      selectedDistTag,
      barStyle,
      districtName: computed(() => store.getters.districtName),
      selectDistrict: (districtId: number) =>
        store.commit(mutations.cntSelect.SELECT_DIST, districtId),
    }
  },
})
