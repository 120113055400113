
import { defineComponent, ref, onMounted, computed } from 'vue'
import { useStore, mutations } from '@/store/index'
import { useRoute } from 'vue-router'
import { AxiosError } from 'axios'
import API from '@/api'
import { DateStr, utcTime, getDateStr } from '@/helper/schedule'
import { changeUrl, setStorage, findFirstDate, checkValid } from '@/helper/home'
import { ISchedule, ModalType, AlertType, _global } from '@/typings'
import { detectTouchMove, counter } from '@/helper/utils'
import Header from '@/components/Header.vue'
import ScheduleBasic from '@/components/ScheduleBasic.vue'
import ScheduleDetail from '@/components/ScheduleDetail.vue'
import Calendar from '@/components/Calendar.vue'
import Modal from '@/components/Modal.vue'
import OptionsSport from '@/components/filterOption/OptionsSport.vue'
import OptionsDist from '@/components/filterOption/OptionsDist.vue'
import Footer from '@/components/Footer.vue'
import smoothscroll from 'smoothscroll-polyfill'
smoothscroll.polyfill()

export default defineComponent({
  name: 'Home',
  components: {
    Header,
    ScheduleBasic,
    ScheduleDetail,
    Calendar,
    OptionsSport,
    OptionsDist,
    Footer,
    Modal,
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const needUpdate = ref(false)
    const cntDate = computed(() => store.state.currentSelect.currentDate)
    const isMobile = computed(() => store.state.control.isMobile)

    const setModal = (type: ModalType) =>
      store.commit(mutations.control.SET_MODAL, type)

    const openAlert = (type: AlertType) =>
      store.commit(mutations.control.SET_ALERT, type)

    const getTimeTableData = () =>
      store.dispatch('GET_MAINDATA').then(() => {
        counter(needUpdate)
        store.commit(mutations.cntSelect.SELECT_DIST, 0)

        cntDate.value
          ? () => store.dispatch('GET_DETAIL')
          : (findFirstDate(), setModal(ModalType.None))
      })

    const tempToStore = async (
      spid?: number,
      dists?: number[],
      hasTimeslot = false
    ) => {
      if (spid && dists) {
        setStorage(spid, dists)
        !hasTimeslot &&
          changeUrl('custom', {
            spid,
            dist: dists.join(','),
          })
        await getTimeTableData()
      } else {
        store.commit(mutations.cntSelect.CHANGE_ALL_SELECT, 'reset')
        store.commit(mutations.main.SET_DATA, {})
        setStorage()
        changeUrl('home')
      }
    }

    const submit = () => {
      const { currentSport: sportId, tempDists: distsId } =
        store.state.currentSelect
      const { dists: distList, sports: sportList } = store.state.mainData

      setStorage(sportId, distsId)
      changeUrl('custom', {
        spid: sportId,
        dist: distsId.join(','),
      })

      store.commit(mutations.cntSelect.CHANGE_CNT_DISTS, [...distsId])
      store.commit(mutations.control.SET_FIRSTVISIT, false)
      store.commit(mutations.cntSelect.CHOSE_DATE, 'reset')
      setModal(ModalType.Loading)
      getTimeTableData()

      _global.sentGA('select_sport', {
        sport_id: sportId,
        sport_name: sportList[sportId].sportName,
      })

      distsId.forEach((id) => {
        _global.sentGA('select_dist', {
          dist_id: distList[id].districtId,
          dist_name: distList[id].districtName,
          selection: `${sportList[sportId].sportName}-${distList[id].districtName}`,
        })
      })
    }

    const findDateInWeek = (date: string) => {
      const weeks = store.state.mainData.columnsPart
      weeks.map((week, i) => {
        week.map((d) => {
          if (d.dateStr === date) {
            store.commit(mutations.cntSelect.CHANGE_WEEK, i)
          }
        })
      })
    }

    const timeslotStatus = (status: string, courtName: string) => {
      switch (status) {
        case 'idle': {
          break
        }
        case 'full':
        case 'expired': {
          store.commit(mutations.modal.SET_MODAL_ERROR, {
            status,
            courtName,
          })

          break
        }
      }
    }

    const validTimeslotHandler = async (
      res: ISchedule.TimeslotData,
      dist: number[]
    ) => {
      const { court, ...obj } = res
      const date = utcTime(obj.start).format(DateStr)
      const timeslotInfo = {
        date: date,
        start: utcTime(obj.start).hour(),
        venueId: obj.venueId,
        districtId: obj.districtId,
      }

      store.commit(mutations.modal.SET_MODAL_SUBDATA, {
        end: utcTime(obj.end).get('hour'),
        priceRange: '',
      })
      store.commit(mutations.modal.SET_MODAL_DATA, timeslotInfo)
      store.commit(mutations.modal.SET_MODAL_BOOKINGINFO, court)
      store.commit(mutations.cntSelect.CHANGE_ALL_SELECT, {
        spid: obj.sportId,
        dists: dist ? dist : [obj.districtId],
      })
      store.commit(mutations.cntSelect.CHOSE_DATE, date)
      store.commit(mutations.control.SET_FIRSTVISIT, false)
      store.commit(mutations.control.SET_BOOKING, true)
      setModal(ModalType.InfoModal)
      findDateInWeek(date)
      timeslotStatus(res.court.status, res.court.courtName)

      await tempToStore(obj.sportId, dist ? dist : [obj.districtId], true)
      store.dispatch('GET_DETAIL')
    }

    const checkTimeslotId = async (
      _timeslot: number,
      _spid: number,
      _dist: number[]
    ) => {
      await API.fetchCourtData(_timeslot)
        .then((res) => {
          validTimeslotHandler(res, _dist)
        })
        .catch((e: AxiosError) => {
          if (_spid && _dist) checkValid(_spid, _dist)
          if (e.response) openAlert(e.response.status)
        })
    }

    const routeQuery = () => {
      const { timeslot, spid, dist } = route.query
      if (!(timeslot || spid || dist)) return false

      const _timeslot = Number(timeslot)
      const _spid = Number(spid)
      const _dist = (dist as string)?.split(',').map((i: string) => Number(i))

      if (_timeslot) {
        checkTimeslotId(_timeslot, _spid, _dist)
      } else if (_spid && _dist) {
        checkValid(_spid, _dist, tempToStore)
      } else if (_spid || _dist) {
        checkValid(_spid, _dist)
      }

      return true
    }

    const localQuery = () => {
      const localSportId = localStorage.getItem('spid')
      const localDistsId = localStorage.getItem('dist')

      if (!localSportId || !localDistsId) return false

      const spid = JSON.parse(localSportId)
      const dists = JSON.parse(localDistsId)
      checkValid(spid, dists, tempToStore)

      return true
    }

    const querySearch = () => {
      let hasHistory = routeQuery()
      if (!hasHistory) {
        hasHistory = localQuery()
        if (!hasHistory) {
          changeUrl('home')
          setModal(ModalType.Directions)
        }
      }
    }

    onMounted(() => {
      store
        .dispatch('GET_INITDATA')
        .then(() => querySearch())
        .catch(() => openAlert(AlertType.ServerError))
    })

    return {
      needUpdate,
      isMobile,
      getTimeTableData,
      submit,
      tempToStore,
      cntDateStr: computed(() => getDateStr(cntDate.value)),
    }
  },
})
