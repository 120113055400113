
import { defineComponent, computed, PropType } from 'vue'
import { ISchedule } from '@/typings'

export default defineComponent({
  name: 'VenueInfo',
  props: {
    venueInfo: {
      type: Object as PropType<ISchedule.VenueInfo>,
      // required: true,
    },
  },

  setup(props) {
    const content = computed(() => {
      return [
        {
          h3: '基本資訊',
          basic: [
            {
              title: '地址',
              text: props.venueInfo?.address,
              link: `https://www.google.com/maps/search/?api=1&query=${props.venueInfo?.venueName}`,
            },
            {
              title: '聯絡電話',
              text: props.venueInfo?.tel,
              link: props.venueInfo?.tel && `tel:+886${props.venueInfo?.tel}`,
            },
            { title: '開放時間', text: props.venueInfo?.openingHours },
            {
              title: '官方網站',
              text: '前往官網',
              link: props.venueInfo?.url,
            },
            {
              title: 'e-mail',
              text: props.venueInfo?.email,
              link:
                props.venueInfo?.email && `mailto:${props.venueInfo?.email}`,
            },
          ],
        },
        {
          h3: '場租資訊',
          text: props.venueInfo?.rentInfo,
        },
        {
          h3: '其他場館設施',
          text: props.venueInfo?.facilities?.join('、'),
        },
      ]
    })

    return { content }
  },
})
