import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("ul", {
    class: _normalizeClass(_ctx.$style.basicInfo)
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.content, (o, i) => {
      return (_openBlock(), _createElementBlock("li", { key: i }, [
        _createElementVNode("small", null, _toDisplayString(o.title), 1),
        _createElementVNode("p", null, _toDisplayString(o.text), 1)
      ]))
    }), 128))
  ], 2))
}