import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DayCourts = _resolveComponent("DayCourts")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cntDists, (_d) => {
    return (_openBlock(), _createElementBlock(_Fragment, { key: _d }, [
      (_ctx.distAndVenues[_d].length)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.distAndVenues[_d], (venueId) => {
            return (_openBlock(), _createBlock(_component_DayCourts, {
              key: venueId,
              venueId: venueId,
              selectVenue: _ctx.selectVenue,
              emptyTimeslots: _ctx.emptyTimeslots
            }, null, 8, ["venueId", "selectVenue", "emptyTimeslots"]))
          }), 128))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createElementVNode("div", {
              class: _normalizeClass([_ctx.$style.venue, 'disable'])
            }, _toDisplayString(_ctx.distName(_d, true)) + "．無場館 ", 3),
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.$style.grid)
            }, [
              _createElementVNode("div", {
                class: _normalizeClass([_ctx.$style.noData, 'disable'])
              }, " 無場館提供 “" + _toDisplayString(_ctx.sportName) + "” 運動 ", 3)
            ], 2)
          ], 64))
    ], 64))
  }), 128))
}