
import { defineComponent } from 'vue'
import { useStore, mutations } from '@/store/index'
import CodeZeroLogo from '@/assets/logos/c0de_logo.svg'
import { ModalType } from '@/typings'

export default defineComponent({
  name: 'Footer',
  components: {
    CodeZeroLogo,
  },
  setup() {
    const store = useStore()

    return {
      openTerms: () =>
        store.commit(mutations.control.SET_MODAL, ModalType.Terms),
    }
  },
})
