
import { defineComponent } from 'vue'
import LogoIcon from '@/assets/logos/logo-icon.svg'

export default defineComponent({
  name: 'Loading',
  components: {
    LogoIcon,
  },
})
